import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from 'redux/actions'
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Room from './Room';
import GuestInformation from 'components/BookingMain/GuestInformation';
import GeneralBookingNotes from 'components/BookingMain/GeneralBookingNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import TravelArrangements from 'components/BookingMain/TravelArrangements';
import ActivityHistory from 'components/BookingMain/ActivityHistory';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_BOOKING_DETAILS_BY_BOOKING_ID } from 'graphql/service';
import { message } from 'antd';
import { CancelledMsg, Msg } from './styles';
import { RESERVATION } from 'constants/ratePlanId';
import moment from 'moment';
import { getMessageTimeOut } from 'utils/util';

const BookingCancelled = () => {

  const bookingId = useParams().bookingId
  const history = useHistory()
  const dispatch = useDispatch()
  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)

  //room 
  const [roomNumber, setRoomNumber] = useState('')
  const [roomType, setRoomType] = useState('')
  const [roomPrice, setRoomPrice] = useState(0)
  const [roomNotes, setRoomNotes] = useState([])

  //TravelArrangements
  const [checkInDate, setCheckInDate] = useState(moment().hour(15).minute(0).second(0))
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1,'days').hour(11).minute(0).second(0))
  
  //GeneralBookingNotes
  const [bookingNotes, setBookingNotes] = useState([])

  const { loading, error, refetch } = useQuery(GET_BOOKING_DETAILS_BY_BOOKING_ID, {
    variables: { bookingId: bookingId },
    onCompleted: (bookingDetailsData) => {
      if(bookingDetailsData) {
        const newCustomer = {...customerInfo}
        const { tenantGroup, isDeleted, checkInDate, checkOutDate, roomBookings, bookingNotes } = bookingDetailsData.bookingByID
        if(!isDeleted) {
          const content = "This booking haven't been cancelled yet!"
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
          history.push(`/booking-details/${bookingId}`)
        }
        setCheckInDate(checkInDate)
        setCheckOutDate(checkOutDate)
        setBookingNotes(bookingNotes)
        if(roomBookings[0]) {
          const {
            pricePerNight, 
            room: {
              roomNumber,
              roomType,
              roomNotes,
            }
          } = roomBookings[0]
          setRoomNumber(roomNumber)
          setRoomType(roomType)
          setRoomNotes(roomNotes)
          setRoomPrice(pricePerNight)
        }
        const { tenant, isPrimaryTenant } = tenantGroup
        const { 
          tenantId,
          user: {
            userId,
            fullName,
            email,
            phoneNumber,
            address
          }
        } = tenant
        newCustomer.userId = userId
        newCustomer.tenantId = tenantId
        newCustomer.isPrimaryTenant = isPrimaryTenant
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = address
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = ''
        dispatch(setCustomerInfo(newCustomer))
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>Booking Cancelled - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <div className='title'>
            <span
              className='highLight'
              onClick={() => {
                history.push('/bookings')
              }}
            >
              Bookings
            </span>
            {` > ${bookingId} ${customerInfo.fullName || ''}`} [CANCELLED]
          </div>
        }
      />
      <Content>
        <ContentColumn>
          <CancelledMsg>
            <Msg>THIS BOOKING HAS BEEN CANCELLED</Msg>
          </CancelledMsg>
          <Container>
            <TravelArrangements
              btnHidden
              disabled
              bookingId={bookingId}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              setCheckInDate={setCheckInDate}
              setCheckOutDate={setCheckOutDate}
            />
          </Container>
          <Container>
            <Room
              disabled
              roomNotes={roomNotes}
              roomType={roomType}
              roomNumber={roomNumber}
              ratePlanId={RESERVATION}
              roomPrice={roomPrice}
              addNoteHidden
              refetch={()=>{
                refetch().then((bookingDetailsData) => {
                  setRoomNotes(bookingDetailsData.data.bookingByID?.roomBookings[0]?.room.roomNotes)
                })
              }}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <GuestInformation
              disabled
              customerInfoError={{}}
              btnInfo={
                <Button
                  mainPage
                  onClick={() => {
                    history.push(`/guest/${customerInfo.tenantId}`)
                  }}
                  additionalstyle={`
                    width: 132px;
                  `}
                >
                  Guest Profile
                </Button>
              }
            />
          </Container>
          <Container>
            <GeneralBookingNotes
              notes={bookingNotes}
              bookingId={bookingId}
              headerString={'GENERAL BOOKING NOTES'}
              refetch={()=>{
                refetch().then((bookingDetailsData) => {
                  setBookingNotes(bookingDetailsData.data.bookingByID?.bookingNotes)
                })
              }}
              addNoteHidden
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
          <Container>
            <ActivityHistory btnHidden bookingId={bookingId}/>
          </Container>
        </ContentColumn>
      </Content>
      <Footer/>
    </>
  )
}

export default BookingCancelled;
