import React from 'react';
import { Container } from './styles';

const ShowInformation = ({ title, description, width, additionalstyle }) => (
  <Container width={width} additionalstyle={additionalstyle}>
    <div className='title'>{title}</div>
    <div className='info'>{description}</div>
  </Container>
);

export default ShowInformation;