import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.green1};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .text {
    color: ${colors.white1};
    margin: 25px 0 15px 0;
  }
`;

export const Row = styled.div`
`;

export const SummaryContainer = styled.div`
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 20px;

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back {
      font-size: 18px;
      font-weight: 700;
      margin-right: 30px;
      color: ${colors.blue1};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
