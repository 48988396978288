import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'antd/es/dropdown/dropdown';
import CheckBox from 'components/Checkbox';
import { Head, Container, Row, SummaryContainer, Footer } from './styles';

const ModalAddRoom = ({hidden, buttonStyle, groupId, refetch}) => {

  const [ isOpen, setIsOpen ] = useState(false)
  const handleAddRoom = () => {

  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Add Room"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 646px;
        .modal-content{
          height: 340px;          
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: 10px;
          }              
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 69px;
      `}
    >
      <Head>Add another room to this booking?</Head>
      <Container>
        <Row>
          <Dropdown 
          />
          <Input />
          <Input />
        </Row>
        <Row>

        </Row>
        <CheckBox />
        <CheckBox />
        <SummaryContainer>

        </SummaryContainer>
      </Container>
      <Footer>
        <Button
          danger
          additionalstyle={`
            height: 43px;
            width: 85px;
          `}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          success
          additionalstyle={`
            height: 43px;
            width: 114px;
          `}
          onClick={handleAddRoom}
        >
          Add Room
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalAddRoom;