import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ModalAddChargeSuccessfully from 'screens/ModalAddChargeSuccessfully';
import ModalAddChargeFailed from 'screens/ModalAddChargeFailed';
import { useMutation } from '@apollo/client';
import { CREATE_CHARGE } from 'graphql/service';
import { Head, Container, Footer } from './styles';


const ModalAddCharge = ({
  hidden,
  buttonStyle,
  billId,
  cardNumber,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
  const [failedIsOpen, setFailedIsOpen] = useState(false)
  const [chargeDetails, setChargeDetails] = useState('')
  const [chargePrice, setChargePrice] = useState('')
  const [createCharge] = useMutation(CREATE_CHARGE, {
    onCompleted: () => {
      setChargePrice('')
      setChargeDetails('')
      setSuccessfullyIsOpen(true)
      setIsOpen(false)
      refetch()
    },
    onError: (error) => {
      setFailedIsOpen(true)
      setIsOpen(false)
    }
  })

  const handleAddCharge = () => {

    createCharge({
      variables: {
        input: {
          billId: billId,
          type: 'CHARGE',
          amount: Number(chargePrice),
          description: chargeDetails,
        },
      },
    });
  }
  
  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel="Add Charge"
        buttonStyle={buttonStyle}
        hidden={hidden}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }          
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 139px;
        `}
      >
        <Head>Add Charge</Head>
        <Container>
          <TextArea      
            title="CHARGE DETAILS"
            required
            rows={4}
            value={chargeDetails}
            onChange={(e) => setChargeDetails(e.target.value)}
            additionalstyle={`
              flex-basis: 100%;
            `}
          />
        </Container>
        <Footer>
          <Input
            title="PRICE"
            required
            error
            errorMessage={''}
            value={chargePrice}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
                setChargePrice(inputValue);
              }
            }}
            additionalstyle={`
              flex-basis: 31%;

              input {
                padding-left: 22px;
              }

              ::before {
                content: '$';
                font-size: 16px;
                font-weight: 600;
                position: absolute;
                left: 12px;
                top: 32px;

                @media only screen and (min-width: 767px) and (max-width: 1200px) {
                  top: 36px;
                }

                @media only screen and (max-width: 767px) {
                  top: 34px;
                }
              }
            `}
          />
          <Button
            success
            disabled={!chargeDetails.length || !chargePrice.length}
            additionalstyle={`
              width: 84px;
              margin-top: 25px;
            `}
            onClick={handleAddCharge}
          >
            Submit
          </Button>
        </Footer>
      </MainModal>
      <ModalAddChargeSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={chargePrice}
        cardNumber={cardNumber}
      />
      <ModalAddChargeFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={chargePrice}
        cardNumber={cardNumber}
        retryFunction={handleAddCharge}
      />
    </>
  )
}

export default ModalAddCharge;
