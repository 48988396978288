import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PriceTable from './components/PriceTable';
import Header from 'components/Header';
import SubHeader from './components/SubHeader';
import moment from 'moment';

const RoomNightReport = () => {
  const [checkInDate, setCheckInDate] = useState(moment().format('MM/DD/YYYY'))
  const [checkOutDate, setCheckOutDate] = useState(moment().add(9, 'days').format('MM/DD/YYYY'))

  return (
    <>
      <Helmet>
        <title>Room Night Report - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalstyle={`
          border: none;
        `}
      />
      <SubHeader
        checkInDate={checkInDate}
        setCheckInDate={setCheckInDate}
        checkOutDate={checkOutDate}
        setCheckOutDate={setCheckOutDate}
      />
      <PriceTable checkInDate={checkInDate} checkOutDate={checkOutDate} />
    </>
  );
};

export default RoomNightReport;
