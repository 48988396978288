import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SocketProvider = ({ children, url, auth }) => {
  const dispatch = useDispatch();
  const connected = useSelector((state) => state.socketReducer.connected);

  useEffect(() => {
    if (connected) return;
    dispatch({
      type: 'socket/connect',
      payload: { url, auth }
    });
  }, [dispatch, connected, url, auth]);
  return children;
};

export default SocketProvider;