import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import ModalGroupCancel from 'screens/ModalGroupCancel';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { RowContainer } from './styles';
import { RESERVATION, ADVANCED } from 'constants/ratePlanId';

const GroupInformation = ({ group }) => {

  const [groupInfo, setGroupInfo] = useState({})
  useEffect(() => {
    const newGroupInfo = {}
    newGroupInfo.groupId = group.groupId
    newGroupInfo.guestName = group.bookings[0]?.fullName
    newGroupInfo.organization = group.organization
    newGroupInfo.ratePlanId = group.ratePlanId
    newGroupInfo.travelDocumentType = group.travelDocumentType
    newGroupInfo.travelDocumentNumber = group.travelDocumentNumber
    newGroupInfo.guestNumber = group.numberOfGuests
    setGroupInfo(newGroupInfo)
  }, [group])

  const handleSaveChanges = () => {
    //TODO: Implement save changes
  }

  return (
    <Card
      additionalstyle={`
        padding: 10px 15px;

        .buttonContainer {
          display: flex;
          column-gap: 10px;
        }
      `}
    >
      <CardHeader>
        <div className='type'>PRIMARY GUEST INFORMATION</div>
        <div className='buttonContainer'>
          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalstyle={`
              width: 122px;
            `}
          >
            Save Changes
          </Button>
        </div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          error={!groupInfo.guestName}
          title="NAME"
          value={groupInfo.guestName || ''}
          onChange={(e) => {
            const newGroupInfo = {...groupInfo}
            newGroupInfo.guestName = e.target.value
            setGroupInfo(newGroupInfo)
          }}
          additionalstyle={`
            flex-basis: 49%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <Input
          mainPage
          title="ORGANIZATION"
          value={groupInfo.organization || ''}
          onChange={(e) => {
            const newGroupInfo = {...groupInfo}
            newGroupInfo.organization = e.target.value
            setGroupInfo(newGroupInfo)
          }}
          additionalstyle={`
            flex-basis: 49%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-top: 25px;
            }
          `}
        />
        <RowContainer>
          <div>
            <Input
              mainPage
              title="TRAVEL DOCUMENT #"
              value={groupInfo.travelDocumentNumber || ''}
              onChange={(e) => {
                const newGroupInfo = {...groupInfo}
                newGroupInfo.travelDocumentNumber = e.target.value
                setGroupInfo(newGroupInfo)
              }}
            />
          </div>
          <div>
            <Dropdown
              mainPage
              options={[
                "Passport",
                "Driver's License"
              ]}
              title="TRAVEL DOCUMENT TYPE"
              value={groupInfo.travelDocumentType}
              onChange={(e) => {
                const newGroupInfo = {...groupInfo}
                newGroupInfo.travelDocumentType = e
                setGroupInfo(newGroupInfo)
              }}
            />
          </div>
        </RowContainer>
        <RowContainer>
          <div></div>
          <div>
            <Input
              mainPage
              title="GUEST #"
              value={groupInfo.guestNumber || 1}
              onChange={(e) => {
                const newGroupInfo = {...groupInfo}
                newGroupInfo.guestNumber = e.target.value
                setGroupInfo(newGroupInfo)
              }}
              additionalstyle={`
                width: 25%;
              `}
            />
            <Dropdown
              mainPage
              options={[{label:'Advanced', value: ADVANCED}, {label:'Reservation', value: RESERVATION}]}
              value={groupInfo.ratePlanId===ADVANCED? 'Advanced': 'Reservation'}
              title="RATE PLAN"
              disabled={true}
              additionalstyle={`
                width: 75%;
              `}
            />
          </div>
        </RowContainer>
      </FormContainer>
    </Card>
  )
}

export default GroupInformation;