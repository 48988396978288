import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Header from 'components/Header';
import Tooltip from 'components/Tooltip';
import Footer from 'components/Footer';
import ModalAddRoom from 'screens/ModalAddRoom';
import ModalGroupCancel from 'screens/ModalGroupCancel';
import ModalCancelConfirm from 'screens/ModalCancelConfirm';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalCheckOutConfirm from 'screens/ModalCheckOutConfirm';
import CreditCards from 'components/BookingMain/CreditCards';
import AddNewCreditCard from 'components/BookingMain/AddNewCreditCard';
import BookingInformation from './BookingInformation';
import GroupInformation from './GroupInformation';
import GeneralGroupNotes from './GeneralGroupNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import BillOfSale from 'components/BookingMain/BillOfSale';
import { useQuery } from '@apollo/client';
import {
  GET_GROUP_DETAILS_BY_GROUP_ID
} from 'graphql/service';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import TransactionHistory from 'components/BookingMain/TransactionHistory';
import StatusBar from 'components/StatusBar';
import moment from 'moment';

const BookingGroup = () => {

  const history = useHistory()
  const groupId = useParams().groupId

  const [groupStatus, setGroupStatus] = useState('')

  //ModalGroupCancel
  const [cancelConfirmIsOpen, setCancelConfirmIsOpen] = useState(false)
  const [cancelRoomParams, setCancelRoomParams] = useState({})
  const [primaryTenantName, setPrimaryTenantName] = useState('')

  //ModalCheckOut
  const [checkOutConfirmIsOpen, setCheckOutConfirmIsOpen] = useState(false)
  const [checkOutBookings, setCheckOutBookings] = useState([])

  //CreditCards
  const [paymentRecords, setPaymentRecords] = useState([])
  const [balance, setBalance] = useState(0)

  //GeneralGroupNotes
  const [groupNotes, setGroupNotes] = useState([])
  const [groupName, setGroupName] = useState([])

  //bookingInformation
  const [bookings, setBookings] = useState([])

  const { data: groupData, loading, error, refetch } = useQuery(GET_GROUP_DETAILS_BY_GROUP_ID, {
    variables: { groupId: groupId },
    onCompleted: (groupData) => {
      if(groupData) {
        const { name, primaryBookingCode, primaryBookingStatus, bookings, groupNotes, paymentCards, bills } = groupData.groupByID
        setGroupStatus(primaryBookingStatus)
        setGroupNotes(groupNotes)
        setGroupName(name)
        setPaymentRecords(paymentCards || [])
        setBalance(bills[0]?.balance || 0)
        setBookings(bookings)
        setPrimaryTenantName(bookings[0]?.fullName)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const earlyCheckIn = bookings.find(booking => (moment() < moment(booking.checkInDate)) && (moment(booking.checkInDate)).set('hour', 3) <= moment())

  const checkedInBookings = bookings.filter(booking => booking.status === 'CHECKED_IN')

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const bookingInformationArray = []
  bookings.forEach((booking, index)=> {
    bookingInformationArray.push(      
      <Container
        key={booking.bookingId}
      >
        <BookingInformation
          booking={booking}
          index={index}
        />
      </Container>      
    )
  })

  return (
    <>
      <Helmet>
        <title>Group Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className="title">
              <span
                className="highLight"
                onClick={() => {
                  history.push("/bookings");
                }}
              >
                Bookings
              </span>
              {` > ${groupName}`}
              <StatusBar status={groupStatus} />
              {groupStatus === "CANCELLED" ? (
                <Tooltip
                  cancelledAt={groupData.groupByID.cancelledAt}
                  cancelledBy={groupData.groupByID.cancelledBy?.fullName}
                  reason={groupData.groupByID.cancellationReason}
                  amount={groupData.groupByID.cancellationFee}
                />
              ) : null}
            </div>
            <div className="buttonContainer">
              <ModalAddRoom
                groupId={groupId}
                refetch={refetch}
                buttonStyle={`
                  width: 96px;
                `}
              />
              {groupStatus === "PENDING" ? (
                <>
                  <Button
                    mainPage
                    additionalstyle={`
                      width: 126px;
                      margin: 0 10px;
                    `}
                    onClick={() => {
                      if (earlyCheckIn) {
                        history.push(
                          `/group-details/early-check-in/${groupId}`
                        );
                      } else {
                        history.push(`/group-details/check-in/${groupId}`);
                      }
                    }}
                  >
                    Group Check In
                  </Button>
                  <ModalGroupCancel
                    hidden={
                      groupStatus === "CANCELLED" ||
                      groupStatus === "CHECKED_IN" ||
                      groupStatus === "CHECKED_OUT"
                    }
                    danger
                    buttonLabel={"Cancel Entire Group"}
                    buttonStyle={`
                      width: 144px;
                    `}
                    setCancelRoomParams={setCancelRoomParams}
                    setConfirmIsOpen={setCancelConfirmIsOpen}
                  />
                </>
              ) : null}
              {checkedInBookings.length > 1 ? (
                <ModalCheckOut
                  buttonStyle={`
                      width: 138px;
                      margin-left: 10px;
                    `}
                  checkedInBookings={checkedInBookings}
                  checkOutBookings={checkOutBookings}
                  setCheckOutBookings={setCheckOutBookings}
                  setCheckOutConfirmIsOpen={setCheckOutConfirmIsOpen}
                />
              ) : null}
              {checkedInBookings.length === 1 ? (
                <ModalCheckOutConfirm
                  buttonStyle={`
                      width: 138px;
                      margin-left: 10px;
                    `}
                  buttonLabel={"Group Check Out"}
                  isOpen={checkOutConfirmIsOpen}
                  setIsOpen={setCheckOutConfirmIsOpen}
                  checkOutBookings={checkedInBookings}
                  refetch={refetch}
                />
              ) : null}
            </div>
          </>
        }
      />
      <ModalCancelConfirm
        isOpen={cancelConfirmIsOpen}
        setIsOpen={setCancelConfirmIsOpen}
        cancelRoomParams={cancelRoomParams}
        fullName={primaryTenantName}
        groupId={groupId}
        refetch={refetch}
      />
      {checkedInBookings.length > 1 ? (
        <ModalCheckOutConfirm
          isOpen={checkOutConfirmIsOpen}
          setIsOpen={setCheckOutConfirmIsOpen}
          checkOutBookings={checkOutBookings}
          refetch={refetch}
        />
      ) : null}
      <Content>
        <ContentColumn>
          <Container>
            <GroupInformation group={groupData.groupByID} />
          </Container>
          <Container>
            <CreditCards
              paymentRecords={paymentRecords}
              balance={balance}
              refetch={refetch}
            />
            <AddNewCreditCard groupId={groupId} />
          </Container>
          <Container>
            <BillOfSale
              group={groupData.groupByID}
              refetch={refetch}
              groupId={groupId}
            />
          </Container>
          <Container>
            <TransactionHistory
              refetch={refetch}
              paymentRecords={paymentRecords}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          {bookingInformationArray}
          <Container>
            <GeneralGroupNotes
              notes={groupNotes}
              groupId={groupId}
              headerString={"GENERAL GROUP NOTES"}
              refetch={() => {
                refetch().then((groupData) => {
                  setGroupNotes(groupData.data.groupByID?.groupNotes);
                });
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  );
}

export default BookingGroup;