import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import SubHeader from './components/SubHeader';
import 'react-calendar-timeline/lib/Timeline.css';
import useTimeline from 'customHook/useTimeline';
import TimelineTable from 'components/TimelineTable';
import ItemRenderer from './components/ItemRenderer';
import generalFakeData from './components/general-fake-data';
import { SideBarItemContainer } from './styles';

const BookingsReport = () => {
  const { timelineState } = useTimeline();
  const { groups, items } = generalFakeData();

  const SideBarItem = groups.map((groupItem) =>
    Object.assign({}, groupItem, {
      title: (<SideBarItemContainer>{groupItem.title}</SideBarItemContainer>),
    }),
  );

  return (
    <>
      <Helmet>
        <title>Bookings Report - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalstyle={`
          border:none
        `}
      />
      <SubHeader timelineState={timelineState} />
      <TimelineTable
        timelineState={timelineState}
        SideBarItem={SideBarItem}
        items={items}
        itemRenderer={ItemRenderer}
        SideBarTitle="Channel"
        sidebarWidth={120}
      />
    </>
  );
};

export default BookingsReport;
