import styled from 'styled-components';
import colors from 'constants/colors';

export const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 50px;
  background: ${colors.black5};
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${colors.grey9};
  z-index: 1000;

  ${(props) => (props.mobile? props.open? `
      position: fixed;
      top: 0;
      @media only screen and (min-width: 1201px){
        display: none;
      }
    `:`display: none;`: null)};    

  ${(props) => props.additionalstyle};
`;

export const Title = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 20px;
  line-height: 50px;
  font-size: 19px;
  font-weight: 300;
  color: ${colors.white1};
  cursor: pointer;


  @media only screen and (max-width: 1200px) {
    left: 10px;
  }
  
  .highLight {
    margin-left: 10px;
    margin-right: 5px;
    font-weight: bold;
  }
`;

export const Alerts = styled.div`
  width: 37px;
  height: 49px;
  position: absolute;
  top: 0px;
  right: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? colors.black1: colors.black5)};

  img {
    width: 19px;
    height: 21px;
    cursor: pointer;
    ${(props) => (props.active ?`filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);`: null)};
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Messages = styled.div`
  width: 46px;
  height: 49px;
  position: absolute;
  top: 0px;
  right: 91px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? colors.black1: colors.black5)};

  img {
    width: 27px;
    height: 23px;
    cursor: pointer;
    ${(props) => (props.active ?`filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);`: null)};
  }
  
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const SubHeader = styled.header`
  display: flex;
  width: 100%;
  height: 47px;
  background: ${colors.grey2};
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  color: ${colors.white1};
  font-size: 14px;
  font-weight: 600;

  .title {
    display: flex;
    align-items: center;
    line-height: 47px;
    height: 47px;

    .highLight {
      color: ${colors.blue1};
      cursor: pointer;
    }
  }
  
  .buttonContainer {
    display: flex;
    align-items: center;
    height: 100%;

    & > div {
      display: flex;
      margin: 10px 0;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height:100%;
    padding: 4px 15px 4px 30px;
    font-size: 16px;

    .title {
      line-height: 65px;
      height: 65px;
    }

    ${(props) => (props.mobileSubHeaderHidden ? 'display: none': null)};
  }

  @media only screen and (max-width: 767px) {
    height:100%;
    font-size: 13px;
    flex-direction: column;
    text-align: center;
    padding: 10px 15px;

    .title {
      line-height: 31px;
      height: 31px;
    }

    ${(props) => (props.mobileSubHeaderHidden ? 'display: none': null)};
  }
`;