import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import ModalRefund from 'screens/ModalRefund';
import { Container, Warning, TransactionContainer } from './styles';
import ShowInformation from 'components/ShowInformation';
import moment from 'moment';

const TransactionHistory = ({paymentRecords, refetch}) => {

  const transactionList = paymentRecords.map((paymentRecord, index) => {

    return (
      <TransactionContainer key={index}>
        <ShowInformation
          width={'33%'}
          description={moment(paymentRecord.createAt).format('MM/DD/YYYY HH:mm A')}
        />
        <ShowInformation
          width={'20%'}
          description={'$' + Number(paymentRecord.transaction.amount).toFixed(2)}
        />
        <ShowInformation
          width={'30%'}
          description={paymentRecord.transaction.creditCardNumber}
        />
        <ModalRefund
          buttonStyle={`
            width: 94px;

            @media only screen and (min-width: 767px) and (max-width: 1200px) {
              width: 112px;
            }
          
            @media only screen and (max-width: 767px) {
              width: 76px;
            }
          `}
          disabled={Number(paymentRecord.transaction.amount) <= 0}
          transactionId={paymentRecord.transaction.transactionId}
          maxRefund={paymentRecord.transaction.amount}
          refetch={refetch}
        />
      </TransactionContainer>
    )
  })

  return (
    <Card
      additionalstyle={`
        padding: 25px 15px;
      `}  
    >
      <CardHeader>
        <div className='type'>TRANSACTION HISTORY</div>
      </CardHeader>
      <Container>
        <TransactionContainer>
          <div className='date'>
            DATE
          </div>
          <div className='amount'>
            AMOUNT
          </div>
          <div className='creditCard'>
            CARD NUMBER
          </div>
        </TransactionContainer>
        {
          transactionList.length? transactionList: 
            <Warning>No transaction has been added.</Warning> 
        }
      </Container>
    </Card>
  )
}

export default TransactionHistory;