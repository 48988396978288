import React, { useState, useEffect } from 'react';
import { usePaymentForm } from 'customHook/usePaymentForm';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentInfo, cleanPaymentInfo } from 'redux/actions'
import Input from 'components/Input';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import InputMask from 'react-input-mask';
import {
  creditCardMask,
  expiryDateMask,
  cvvMask,
} from './inputMasks'
import { detectCardType } from 'utils/detectCardType';
import { usePlacesWidget } from 'react-google-autocomplete'
import checkMark from 'assets/svg/checkMark.svg'

const PaymentMethod = ({ creditCardType, setCreditCardType }) => {

  const [coverInput, setCoverInput] = useState('')
  const dispatch = useDispatch()
  const paymentInfo = useSelector(state => state.paymentInfoReducer.paymentInfo)
  const { paymentError, setPaymentError } = usePaymentForm(creditCardType)

  useEffect(()=> {
    return () => {
      dispatch(cleanPaymentInfo())
      setCoverInput('')
      creditCardMask.mask = '9999 9999 9999 9999'
    }    
  },[dispatch])

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      dispatch(setPaymentInfo((paymentInfo) => ({...paymentInfo, billingAddress: place.formatted_address})))
    },
    options: {
      types: ['address'],
    },
  }) 

  const onChangeCreditCard = (e) => {
    
    setCoverInput(e.target.value)
    dispatch(setPaymentInfo({...paymentInfo, creditCardNumber: e.target.value}))
    if(e.target.value.length < 19) {
      setCreditCardType('')
      setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
    } else {
      const type = detectCardType(e.target.value.replace(/\s/g, ''))
      if(type.length > 0) {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
      } else {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
      }
      setCreditCardType(type)
    }
  }

  return (
    <Card
      additionalstyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader>
        <div className='type'>PAYMENT METHOD</div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          title="CARDHOLDER NAME"
          value={paymentInfo.cardHolderName}
          error={paymentError.cardHolderNameError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardHolderName: e.target.value}))
            if(paymentError.cardHolderNameError) {
              setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: false}))
            }
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <InputMask
          mask={creditCardMask.mask}
          maskPlaceholder=''
          value={coverInput}
          error={paymentError.creditCardNumberError}
          onChange={onChangeCreditCard}
        >
          <Input
            mainPage
            title="CREDIT CARD"
            maxLength={19}
            additionalstyle={`
              width: 70%;
              margin-right:2%;
              input{
                ${
                  creditCardType? `
                    background-image: url(${checkMark});
                    background-repeat: no-repeat;
                    background-position: 80% 50%;
                  `: null
                }
              }
            `}
          />
        </InputMask>
        <InputMask
          mask={expiryDateMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardExpirationDate}
          error={paymentError.cardExpirationDateError}
          onChange={(e)=> {
            dispatch(setPaymentInfo({...paymentInfo, cardExpirationDate: e.target.value}))
            if(paymentError.cardExpirationDateError) {
              setPaymentError((paymentError)=>({...paymentError, cardExpirationDateError: false}))
            }
          }}
        >
          <Input
            mainPage
            title="EXPIRY"
            additionalstyle={`
              width: 12%;
              margin-right: 2%;
            `}
          />
        </InputMask>
        <InputMask
          mask={cvvMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardCVV}
          error={paymentError.cardCVVError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardCVV: e.target.value}))
            if(paymentError.cardCVVError) {
              setPaymentError((paymentError)=>({...paymentError, cardCVVError: false}))
            }
          }}
        >
          <Input
            mainPage
            title="CVV"
            additionalstyle={`
              width: 14%;
            `}
          />
        </InputMask>
        <Input
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={paymentInfo.billingAddress}
          error={paymentError.billingAddressError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, billingAddress: e.target.value}))
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
      </FormContainer>
    </Card>
  )
}

export default PaymentMethod;

