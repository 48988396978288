import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import createSocketMiddleware from 'socket/socketMiddleware';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    { serializableCheck: false }
  ).concat(createSocketMiddleware()),
});

export default store;