import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useMutation } from '@apollo/client';
import { CREATE_REFUND } from 'graphql/service';
import { Head, Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalRefund = ({
  hidden,
  disabled,
  buttonStyle,
  transactionId,
  maxRefund,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [refundDetails, setRefundDetails] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const [createRefund] = useMutation(CREATE_REFUND, {
    onCompleted: ()=>{
      refetch()
      setIsOpen(false)
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleRefund = () => {
    createRefund({
      variables: {
        input: {
          transactionId,
          refundAmount: Number(refundAmount),
          refundReason: refundDetails
        },
      },
    });
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Refund"
      buttonStyle={buttonStyle}
      disabled={disabled}
      hidden={hidden}
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          .close {
            z-index: 200;
          }
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 139px;
      `}
    >
      <Head>Refund</Head>
      <Container>
        <TextArea      
          title="REFUND DETAILS"
          required
          rows={4}
          value={refundDetails}
          onChange={(e) => setRefundDetails(e.target.value)}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
      </Container>
      <Footer>
        <Input
          title="REFUND AMOUNT"
          required
          value={refundAmount}
          onChange={(e) => setRefundAmount(e.target.value)}
          additionalstyle={`
            flex-basis: 31%;

            input {
              padding-left: 22px;
            }

            ::before {
              content: '$';
              font-size: 16px;
              font-weight: 600;
              position: absolute;
              left: 12px;
              top: 32px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                top: 36px;
              }

              @media only screen and (max-width: 767px) {
                top: 34px;
              }
            }
          `}
        />
        <Button
          success
          disabled={!refundAmount || !refundDetails || Number(refundAmount) > maxRefund}  
          additionalstyle={`
            width: 84px;
            margin-top: 25px;
          `}
          onClick={handleRefund}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalRefund;
