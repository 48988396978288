import styled from 'styled-components';
import colors from 'constants/colors'

export const TimePickerContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  .MuiTextField-root {
    ${({ additionalstyle }) => additionalstyle};
  }

  div .MuiOutlinedInput-root {
    padding: 0;
    :focus-visible {
      outline: none;
    }
    fieldset {
      border: none;
    }
  }  

  input {
    box-sizing: border-box;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif; 
    color: ${colors.white1};
    padding: 9px 10px;

    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      height: 46px;
      font-size: 15px;
    }

    @media only screen and (max-width: 767px) {
      height: 44px;
      font-size: 13px;
    }
  }

  .Mui-disabled {
    -webkit-text-fill-color: ${colors.white1} !important;
  }

  button {
    position: absolute;
    left: 0;
  }

  button:focus {
    outline: none;
  }
`;