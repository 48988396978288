import React from 'react';
import { Container } from './styles';

const Input = ({
  onChange,
  onBlur,
  value,
  title,
  placeholder,
  additionalstyle,
  required,
  name,
  type,
  defaultValue,
  inputFieldRef,
  mainPage,
  disabled,
  readOnly,
  maxLength,
  error,
  errorMessage
}) => {
  return (
    <Container
      additionalstyle={additionalstyle}
      mainPage={mainPage}
      error={error}
      disabled={disabled}
    >
      {title && <div className='label'>
        {title}
        {required? <span className='warning'> *</span>: null}
      </div>}
      <input
        disabled={disabled}
        className='input'
        autoComplete="new-password"
        name={name}
        type={type}
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        defaultValue={defaultValue}
        maxLength={maxLength}
        ref={inputFieldRef}
      />
      {error? <div className="warning">{errorMessage?.length? errorMessage: 'This is a required field.'}</div>: null}
    </Container>
  );
}

export default Input;