import React from 'react';
import Button from 'components/Button';
import RemoveIcon from 'assets/svg/Remove.svg';
import {
  Container,
  RemoveContainer,
  ContentContainer,
  Header,
  Content,
  ButtonContainer,
} from './styles';
import colors from 'constants/colors';

const Note = ({
  date,
  author,
  description,
  price,
  disabled,
  onRemove,
  mobileButtonTitle,
  padButtonTitle,
  buttonTitle,
  onClickButton,
}) => (
  <Container>
    {!disabled ? (
      <RemoveContainer onClick={onRemove}>
        <img src={RemoveIcon} alt={'removeIcon'}/>
      </RemoveContainer>
    ): null}
    <ContentContainer disabled>
      <Header>
        <span>{date}</span>
        <span>{author && ' by '}</span>
        <span>{author}</span>
      </Header>
      <Content>
        <div className='description'>
          <span>{description}</span>
          {description.name? 
            <>
              <span className='highLight'>{description.name}</span>
              <span>with</span>
              <span className='highLight'>{description.group}</span>
            </>
          : null}
        </div>
        {price && (
          <div className='price'>
            <span>{price}</span>
          </div>
        )}
      </Content>
    </ContentContainer>
    {buttonTitle && (
      <ButtonContainer>
        <Button
          mainPage
          onClick={onClickButton}
          additionalstyle={`
            width: 159px;

            @media only screen and (min-width: 767px) and (max-width: 1200px) {
              height: 23px;
              width: 172px;
              background: transparent;
              color: ${colors.blue1};
              font-size: 15px;
              font-weight: 600;
            }
          
            @media only screen and (max-width: 767px) {
              height: 20px;
              width: 65px;
              background: transparent;
              color: ${colors.blue1};
              font-size: 13px;
              font-weight: 600;
            }
          `}
        >
          <div className="mobileButtonTitle">{mobileButtonTitle}</div>
          <div className="padButtonTitle">{padButtonTitle}</div>
          <div className="buttonTitle">{buttonTitle}</div>
        </Button>
      </ButtonContainer>
    )}
  </Container>
);

export default Note;
