import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SubHeader from './components/Timeline/SubHeader';
import 'react-calendar-timeline/lib/Timeline.css';
import useTimeline from 'customHook/useTimeline';
import TimelineTable from 'components/TimelineTable';
import ItemRenderer from './components/Timeline/ItemRenderer';
import GroupLabel from './components/Timeline/GroupLabel';
import ClientModal from './components/ClientModal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ALL_VALID_BOOKINGS,
  GET_ALL_ROOMS,
  UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID,
  GET_BOOKING_DETAILS_BY_BOOKING_ID
} from 'graphql/service';
import { SideBarItemContainer } from './styles';
import { scrollDown } from 'utils/scrollDown';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const Bookings = () => {
  
  const location = useLocation()
  const roomRef = useRef()
  const { timelineState, generateBookingData } = useTimeline();
  const [clientModalOpen, setClientModalOpen] = useState(false);
  const [bookingId, setBookingId] = useState('');
  const [bookingDetailsData, setBookingDetailsData] = useState({})
  const { data: roomsData, loading, error } = useQuery(GET_ALL_ROOMS);
  const [getAllValidBookings, { data: bookingsData, loading: bookingLoading, error: bookingError, refetch }] = useLazyQuery(
    GET_ALL_VALID_BOOKINGS,
    {
      pollInterval: 1200000,
    }
  );
  const scrollToRoom = () => scrollDown(roomRef.current?.offsetTop, 1000)
  
  const [getBookingDetails, { refetch: refetchBookingDetailsData }] = useLazyQuery(
    GET_BOOKING_DETAILS_BY_BOOKING_ID,
    {
      onCompleted: (data) => {
        setBookingDetailsData(data)
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if(bookingId.length>0) {
      getBookingDetails({
        variables: {
          bookingId
        }
      })
    }
  }, [bookingId, getBookingDetails])

  const [updateRoomBooking, {error: updateRoomBookingError}] = useMutation(
    UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID,
    { 
      onCompleted: () => {
        setClientModalOpen(false)
        refetch()
        setBookingId('')
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    } 
  )

  useEffect(() => {
    getAllValidBookings()
  }, [getAllValidBookings])

  useEffect(() => {
    if(roomRef.current) {
      scrollToRoom() 
    }
  }, [])

  const handleItemClick = (itemId, e, time) => {
    setClientModalOpen(true)
    setBookingId(itemId)
  }

  if (loading || bookingLoading) return <div>Loading</div>
  if (error || bookingError) return <div>error</div>

  const { groups, items } = generateBookingData(roomsData, bookingsData);
  let SideBarItem = []
  let GroupLabels = []
  let top = 152
  for(const key in groups) {
    const group = groups[key].map((groupItem) => {
      return Object.assign({}, groupItem, {
        title: (
          <SideBarItemContainer ref={groupItem.id===location.state?.roomNumber? roomRef: null} warning={groupItem.status==='OUT_OF_ORDER'}>
            {groupItem.id}
            <div className={groupItem.status==='OUT_OF_ORDER'? 'warningCover': null}></div>
          </SideBarItemContainer>
        )
      })
    })
    SideBarItem = SideBarItem.concat(group)
    GroupLabels.push(
      <GroupLabel key={key} groupName={key} height={groups[key].length * 32 - 1} top={top}/>
    )
    top += groups[key].length * 32
  }

  const handleItemMove = (itemId, dragTime, newGroupOrder) => {
    updateRoomBooking({
      variables: {
        input: {
          roomId: SideBarItem[newGroupOrder].roomId,
          bookingId: itemId
        },
      },
    })
    if(error) {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Bookings - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalstyle={`
          position: fixed;
          top: 0;
          border: none;
        `} 
      />
      <SubHeader timelineState={timelineState} />
      <TimelineTable
        timelineState={timelineState}
        SideBarItem={SideBarItem}
        items={items}
        itemRenderer={ItemRenderer}
        ClientModal={ClientModal}
        bookingDetailsData={bookingDetailsData}
        handleItemClick={handleItemClick}
        handleItemMove={handleItemMove}
        clientModalOpen={clientModalOpen}
        setClientModalOpen={setClientModalOpen}
        fixedHeader
      />
      {GroupLabels}
      <Footer />
    </>
  );
};

export default Bookings;
