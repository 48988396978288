import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

let socket;
const eventCallbacks = new Map();

const createSocketMiddleware = () => {

  return store => next => action => {
    switch (action.type) {
      case 'socket/connect':
        if (socket) {
          console.log('socket already connected');
          socket.close();
        }

        socket = io(action.payload.url, {
          auth: action.payload.auth || {},
          transports: ['websocket'],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: 10
        });

        socket.io.opts.pingInterval = 10000;
        socket.io.opts.pingTimeout = 5000;

        socket.on('connect', () => {
          const id = uuidv4();
          socket.emit('PMS_CONNECT', id)
          console.log('socket connected');
          store.dispatch({ type: 'socket/connected' });
        });

        socket.on('disconnect', () => {
          console.log('socket disconnected');
          store.dispatch({ type: 'socket/disconnected' });
        });

        socket.on('error', (error) => {
          store.dispatch({ 
            type: 'socket/error',
            payload: error 
          });
        });

        socket.onAny((event, data) => {
          if (eventCallbacks.has(event)) {
            eventCallbacks.get(event)(data);
          }
        });

        break;

      case 'socket/disconnect':
        if (socket) {
          socket.close();
          socket = null;
        }
        break;

      case 'socket/emit':
        if (socket) {
          const { event, data } = action.payload;
          socket.emit(event, data);
        } else {
          console.error('Socket is not connected');
        }
        break;

      case 'socket/on': {
        const { event, callback } = action.payload;
        if (!eventCallbacks.has(event)) {
          eventCallbacks.set(event, callback);
        }
        break;
      }
  
      case 'socket/off': {
        const { event } = action.payload;
        if (eventCallbacks.has(event)) {
          eventCallbacks.delete(event);
        }
        break;
      }

      default:
        break;
    }

    return next(action);
  };
};

export default createSocketMiddleware;