import React from 'react';
import { PriceSubHeaderContainer } from '../style';
import StyledDatePicker from 'components/DatePicker';
import colors from 'constants/colors';
import moment from 'moment';

const SubHeader = ({ checkInDate, checkOutDate, setCheckInDate, setCheckOutDate }) => {
  return (
    <PriceSubHeaderContainer>
      <div className="datePickerContainer">
        <div className="datePicker">
          <StyledDatePicker
            dateValue={moment(checkInDate)}
            dateOnChange={setCheckInDate}
            variant="standard"
            placeholder="Select start date"
            additionalstyle={`
              width: 137px;

              input{
                font-weight: 400;
                padding-left: 40px;
                padding-right: 0px;
                border-bottom: 2px solid ${colors.grey1};
              }

              .MuiInput-root {
                margin-top: 0;
              }
            `}
          />
        </div>
        <div className="datePicker">
          <StyledDatePicker
            dateValue={moment(checkOutDate)}
            dateOnChange={setCheckOutDate}
            variant="standard"
            placeholder="Select end date"
            additionalstyle={`
              width: 137px;

              input{
                font-weight: 400;
                padding-left: 40px;
                padding-right: 0px;
                border-bottom: 2px solid ${colors.grey1};
              }

              .MuiInput-root {
                margin-top: 0;
              }
            `}
          />
        </div>
      </div>
    </PriceSubHeaderContainer>
  );
};

export default SubHeader;
