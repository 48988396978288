import styled from 'styled-components';
import colors from 'constants/colors';
import DropDownArrowIcon from 'assets/icons/DropdownArrow.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  
  span {
    font-size: ${(props) => (props.mainPage ? '12px' : '11px')};
    font-weight: ${(props) => (props.mainPage ? '600' : '300')};
    letter-spacing: 1.5px;
    white-space: nowrap;
    margin-bottom: 5px;
    color: ${(props) => (props.error? colors.red1:props.mainPage ? colors.grey5 : colors.white1)};
  }

  .select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: ${(props) => (props.disabled? colors.grey30: colors.white1)};
    background-color: ${(props) => (props.disabled? colors.grey31: props.mainPage ? 'transparent' : colors.grey17)};
    height: 40px;
    line-height: 40px;
    background-image: ${({disabled})=>disabled? 'none': `url(${DropDownArrowIcon})`};
    background-position: 95% 50%;
    background-repeat: no-repeat;
    border: 1px solid ${(props) => (props.error ? colors.red1 : colors.grey1)};
    border-radius: 0;
    font-size: ${(props) => (props.mainPage ? '14px' : '16px')};
    font-weight: ${(props) => (props.mainPage ? '600' : 'bold')};
    min-width: 71px;
    letter-spacing: 0.5px;
    padding: 0px 10px;

    ${({disabled})=>disabled? `
        background-image: none;
      `:null};
    
    ${(props) => props.mainPage ? 'background-size: 10px 10px': null};
  }

  .options {
    max-height: 290px;
    position: absolute;
    overflow-y: scroll;
    top: ${(props) => (props.title ? '60px' : '40px')};
    letter-spacing: 0.5px;
    width: 100%;
    border: solid ${colors.grey1};
    border-width: 0 1px 1px 1px; 
    z-index: 2500;

    ::-webkit-scrollbar { 
      width: 0 !important; 
    }

    ::-moz-scrollbar {
      width: 0 !important; 
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    span {
      font-size: 11px;
    }

    .select {
      height: 46px;
      line-height: 46px;
      font-size: ${(props) => (props.mainPage ? '15px' : '18px')};
    }

    .options {
      top: ${(props) => (props.title ? '66px' : '46px')};
    }
  }

  @media only screen and (max-width: 767px) {
    
    span {
      font-size: 11px;
    }

    .select {
      height: 44px;
      line-height: 44px;
      font-size: ${(props) => (props.mainPage ? '13px' : '18px')};
    }

    .options {
      top: ${(props) => (props.title ? '64px' : '44px')};
    }
  }

  ${(props) => props.additionalstyle};
`;

export const StyledItem = styled.div`
  width: 100%;
  height: 36px;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 10px;
  color: ${colors.white1};
  background-color: ${colors.grey17};
  overflow: hidden;

  .highLight {
    color: ${colors.white1};
  }

  :hover {
    color: ${colors.blue1};
    border-left: 5px solid ${colors.blue1};
    background-color: ${colors.black9};
    padding-left: 5px;

    .highLight {
      font-weight: bold;
      color: ${colors.blue1};
    }
  }
`;