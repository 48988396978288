import React from 'react';
import { Container, ViewingContainer, RemainingContainer, Title, RemainingItem } from './styles';
import moment from 'moment';

export const Viewing = ({remaining, bookingId}) => {

  return (
    <Container>
      <ViewingContainer>
        <Title>Now Viewing</Title>
        {
          remaining.length? (
            remaining.filter(item=>item.bookingId===bookingId).map((item, index) => {
              return (
                  <RemainingItem
                    key={index}
                    additionalstyle={`
                      border: none;
                    `}
                  >
                    <div>Arrived {moment(item.checkinByCheckinId?.createAt).format('D/M/YYYY @ h:mm A')}</div>
                    <div>{item.name}</div>
                  </RemainingItem>
              )               
            })
          ): (
            <RemainingItem
              additionalstyle={`
                border: none;
              `}
            >
              <div>Arrived 21/6/2020 @ 5:13 PM</div>
              <div>Jane Doe</div>
            </RemainingItem>
          )
        }
      </ViewingContainer>
    </Container>
  )
}

export const Remaining = ({remaining, bookingId}) => {  

  return (
    <Container>
      <RemainingContainer>
        {
          remaining.length? (
            <>
              <Title>Remaining ({remaining.length-1})</Title>
              {
                remaining.filter(item=>item.bookingId!==bookingId).map((item, index) =>  {
                  return (
                    <RemainingItem key={index}>
                      <div>Arrived 21/6/2020 @ 5:13 PM</div>
                      <div>{item.name}</div>
                    </RemainingItem>
                  )
                })
              }
            </>
          ): (
            <>
              <Title>Remaining (4)</Title>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
            </>
          )
        }
      </RemainingContainer>
    </Container>
  )
}