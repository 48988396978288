import {combineReducers} from 'redux'
import socketReducer from './socketSlice'
import groupCheckInModalStatusReducer from './groupCheckInModalStatusSlice'
import {
    RESET_REDUX, 
    SET_USER,
    SET_ROOM,
    SET_GUEST,
    CLEAN_SELECTION,
    CLEAN_GUEST,
    SET_CUSTOMER_INFO,
    SET_STAFF_INFO,
    SET_PAYMENT_INFO,
    CLEAN_PAYMENT_INFO,
    SET_PICKED_DATE,   
} from './action-types'

const initUserState = {
    user: {
        userId: '',
        username: '',
        userPhoneNumber: '',
        userEmail: '',
    }
}

const userReducer = (state = initUserState, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.data }
        default:
            return state
    }
}

const initSelectState = {
    selectedRoom: null,
    selectedGuest: null
}

const selectReducer = (state = initSelectState, action) => {
    switch (action.type) {
        case SET_GUEST:
            return { ...state, selectedGuest: action.data }
        case SET_ROOM:
            return { ...state, selectedRoom: action.data }
        case CLEAN_SELECTION:
            return { ...state, selectedRoom: null }
        case CLEAN_GUEST:
            return { ...state, selectedGuest: null }    
        default:
            return state
    }
}

const initCustomerInfoState = {
    customerInfo: {
        tenantId: '',
        userId: '',
        isPrimaryTenant: false,
        fullName: '',
        phoneNumber: '',
        email: '',
        billingAddress: '',
        travelDocumentNumber: '',
        travelDocumentType: ''
    }
}

const customerInfoReducer = (state = initCustomerInfoState, action) => {
    switch (action.type) {
        case SET_CUSTOMER_INFO:
            return { ...state, customerInfo: action.data }
        default:
            return state
    }
}

const initStaffInfoState = {
    staffInfo: {
        staffId: '',
        userId: '',
        fullName: '',
        phoneNumber: '',
        email: '',
        address: '',
        roles: '',
        status: '', 
    }
}

const staffInfoReducer = (state = initStaffInfoState, action) => {
    switch (action.type) {
        case SET_STAFF_INFO:
            return { ...state, staffInfo: action.data }
        default:
            return state
    }
}

const initPaymentInfoState = {
    paymentInfo: {
        cardHolderName: '',
        creditCardNumber: '',
        cardExpirationDate: '',
        cardCVV: '',
        billingAddress: '',
    }
}

const paymentInfoReducer = (state = initPaymentInfoState, action) => {
    switch (action.type) {
        case SET_PAYMENT_INFO:
            return { ...state, paymentInfo: action.data }
        case CLEAN_PAYMENT_INFO:
            return initPaymentInfoState
        default:
            return state
    }
}

const initPickedDateState = {
    pickedDate: Date.now()
}

const pickedDateReducer = (state = initPickedDateState, action) => {
    switch (action.type) {
        case SET_PICKED_DATE:
            return { ...state, pickedDate: action.data }
        default:
            return state
    }
}

const appReducer = combineReducers({
    userReducer,
    selectReducer,
    customerInfoReducer,
    staffInfoReducer,
    paymentInfoReducer,
    pickedDateReducer,
    socketReducer,
    groupCheckInModalStatusReducer
})

const rootReducer = (state, action) => {
    if (action.type === RESET_REDUX) {
      return appReducer(undefined, action)
    }  
    return appReducer(state, action)
  }

export default rootReducer