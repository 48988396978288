import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Container } from './styles';
import { TooltipIcon } from 'assets/icons';
import moment from 'moment';

const TooltipComponent = ({
  cancelledAt,
  cancelledBy,
  reason,
  amount
}) => {

  return (
    <Tooltip
      placement="right"
      trigger={['hover']}
      overlay={
        <Container>
          <div>
            <span className='cancelledAt'>
              {moment(cancelledAt).format('M/D/YYYY h:mm A') + ' by '}
              </span>
            <span className='cancelledBy'>{cancelledBy}</span>
          </div>
          <div className="tooltip-title">Reason for Cancellation:</div>
          <div className="tooltip-text">{reason}</div>
          <div className="tooltip-title">
            <span>Cancellation Amount:</span>
            <span className="tooltip-highLight"> {' $' + amount}</span>
          </div>
        </Container>
      }
    >
      <TooltipIcon />
    </Tooltip>
  );
}

export default TooltipComponent;
