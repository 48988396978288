import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  background-color: ${colors.grey3}; 
  padding: 5px 10px;
`;

export const TransactionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .date, .amount, .creditCard {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5};
    margin-top: 10px;
  }

  .date {
    width: 33%;
  }

  .amount {
    width: 20%;
  }

  .creditCard {
    width: 30%;
  }
`;

export const Warning = styled.div`
  color: ${colors.red1};
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.5px;
  margin-bottom: 10px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;

