import styled from 'styled-components';
import colors from 'constants/colors';

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  padding: 11px auto;
  height: 40px;
  background:   ${({disabled, danger, success}) => 
    disabled? colors.grey30:
    danger? colors.red2:
    success? colors.green1:
    colors.blue1};
  color: ${({disabled}) => (disabled ? colors.grey31 : colors.black5)};
  font-weight: bold;
  font-size: ${({mainPage}) => (mainPage ? '14px' : '16px')};
  
  :focus {
    outline: none;
  }
  ${({hidden}) => hidden? `display: none;`: null};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: 46px;
    font-size: ${(props) => (props.mainPage ? '16px' : '18px')};
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }

  @media only screen and (max-width: 767px) {
    height: 44px;
    font-size: ${(props) => (props.mainPage ? '13px' : '18px')};
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }

  ${({ additionalstyle }) => additionalstyle};
`;
