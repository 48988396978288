import { createSlice } from '@reduxjs/toolkit';

const groupCheckInModalStatusSlice = createSlice({
  name: 'groupCheckInModalStatus',
  initialState: { 
    activeModal: null,
    data: {}
  }, 
  reducers: {
    openModal: (state, action) => {
      state.activeModal = action.payload.modal; // Set the active modal by name
      state.data = {...state.data, ...action.payload.data}; // Set the data for the modal
    },
    closeModal: (state) => {
      state.activeModal = null; // Close all modals
    },
  },
});

export const { openModal, closeModal } = groupCheckInModalStatusSlice.actions;
export default groupCheckInModalStatusSlice.reducer;