import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import StatusBar from 'components/StatusBar';
import Tooltip from 'components/Tooltip';
import ModalBookingCancel from 'screens/ModalBookingCancel';
import ModalCancelConfirm from 'screens/ModalCancelConfirm';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { Row, RowContainer, TravelInputRow, InputRow } from './styles';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOKING_BY_BOOKING_ID } from 'graphql/service';
import { OpenNewPageIcon } from 'assets/icons';
import colors from 'constants/colors';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import moment from 'moment';
import ModalChangeRoom from 'screens/ModalChangeRoom';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalCheckOutConfirm from 'screens/ModalCheckOutConfirm';

const BookingInformation = ({
  booking,
  index
}) => {

  const history = useHistory()
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [cancelRoomParams, setCancelRoomParams] = useState({})

  const [bookingInfo, setBookingInfo] = useState({
    bookingId: booking.bookingId,
    roomType: booking.roomBookings[0]?.room.roomType,
    roomNumber: booking.roomBookings[0]?.room.roomNumber,
    roomId: booking.roomBookings[0]?.room.roomId,
    checkInDate: booking.checkInDate,
    checkOutDate: booking.checkOutDate,
    price: booking.roomBookings[0]?.room.pricePerNight,
    isWheelchairAccessible: booking.roomBookings[0]?.room.barrierFree,
    status: booking.status,
    cancelledAt: booking.cancelledAt,
    cancelledBy: booking.cancelledBy?.fullName,
    cancellationReason: booking.cancellationReason,
    cancellationFee: booking.cancellationFee
  })
  
  const [updateBooking] = useMutation(UPDATE_BOOKING_BY_BOOKING_ID,
    {
      onCompleted: (updateBookingData) => {
        message.success('Booking updated successfully')
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  
  const handleSaveChanges = () => {
    updateBooking({
      variables: {
        bookingId: bookingInfo.bookingId,
        input: {
          checkInDate: bookingInfo.checkInDate,
          checkOutDate: bookingInfo.checkOutDate
        }
      }
    })
  }

  return (
    <Card
      additionalstyle={`
        padding: 10px 15px;

        .buttonContainer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          column-gap: 10px;

          .openNewPageIcon {
            cursor: pointer;
          }
        }
      `}
    >
      <CardHeader>
        <div className="type">
          <div>ROOM {index + 1}</div>
          <div
            className="icon"
            onClick={() => {
              history.push(`/booking-details/${bookingInfo.bookingId}`);
            }}
          >
            <OpenNewPageIcon />
          </div>
          <StatusBar status={bookingInfo.status} />
          {bookingInfo.status === "CANCELLED" ? (
            <Tooltip
              cancelledAt={bookingInfo.cancelledAt}
              cancelledBy={bookingInfo.cancelledBy}
              reason={bookingInfo.cancellationReason}
              amount={bookingInfo.cancellationFee}
            />
          ) : null}
        </div>
        <div className="buttonContainer">
          <ModalBookingCancel
            hidden={
              bookingInfo.status === "CANCELLED" ||
              bookingInfo.status === "CHECKED_IN" ||
              bookingInfo.status === "CHECKED_OUT"
            }
            danger
            buttonLabel={"Cancel This Room"}
            buttonStyle={`
              width: 144px;
            `}
            setCancelRoomParams={setCancelRoomParams}
            setConfirmIsOpen={setConfirmIsOpen}
          />
          {bookingInfo.status === "CHECKED_IN" ? (
            <ModalCheckOutConfirm
              buttonLabel={"Check Out"}
              buttonStyle={`
                width: 96px;
              `}
              checkOutBookings={[booking]}
            />
          ) : null}

          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalstyle={`
              width: 113px;
            `}
          >
            Save Changes
          </Button>
        </div>
      </CardHeader>
      <ModalCancelConfirm
        isOpen={confirmIsOpen}
        setIsOpen={setConfirmIsOpen}
        cancelRoomParams={cancelRoomParams}
        roomNumber={bookingInfo.roomNumber}
        bookingId={bookingInfo.bookingId}
      />
      <FormContainer>
        <RowContainer>
          <Row>
            <Dropdown
              mainPage
              disabled
              title="ROOM #"
              value={bookingInfo.roomNumber}
              additionalstyle={`
                flex-basis: 40%;
              `}
            />
            <Dropdown
              mainPage
              disabled
              title="ROOM TYPE"
              value={bookingInfo.roomType?.name}
              additionalstyle={`
                flex-basis: 60%;
              `}
            />
          </Row>
          <Row>
            <InputRow>
              <Dropdown
                mainPage
                options={[1, 2]}
                value={bookingInfo.roomType?.capacity}
                title="CAPACITY"
                disabled={true}
                additionalstyle={`
                  width: 40%;
                `}
              />
              <Dropdown
                mainPage
                options={["true", "false"]}
                value={
                  bookingInfo.isWheelchairAccessible
                    ? bookingInfo.isWheelchairAccessible + ""
                    : "false"
                }
                title="BARRIER FREE"
                disabled={true}
                additionalstyle={`
                  width: 60%;
                `}
              />
            </InputRow>
            <Button
              mainPage
              additionalstyle={`
                width: 122px;

                @media only screen and (max-width: 767px) {
                  width: 100%;
                }
              `}
              onClick={() => {
                //FIXME: This is not working
                history.push(`/room-details/${bookingInfo.roomId}`);
              }}
            >
              Change Room
            </Button>
          </Row>
        </RowContainer>
        <ModalChangeRoom bookingId={bookingInfo.bookingId} />
        <TravelInputRow>
          <div>
            <div className="datePickerContainer">
              <span>ARRIVAL</span>
              <DatePicker
                error={
                  bookingInfo.checkInDate
                    ? bookingInfo.checkInDate >= bookingInfo.checkOutDate
                    : false
                }
                minDate={moment(new Date())}
                dateValue={moment(bookingInfo.checkInDate)}
                dateOnChange={(date) => {
                  setBookingInfo({ ...bookingInfo, checkInDate: date });
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>ARRIVAL TIME</span>
              <TimePicker
                TimeValue={moment(bookingInfo.checkInDate)}
                setTime={(e) => {
                  setBookingInfo({ ...bookingInfo, checkInDate: e });
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
          <div>
            <div className="datePickerContainer">
              <span>DEPARTURE</span>
              <DatePicker
                error={
                  bookingInfo.checkInDate
                    ? bookingInfo.checkInDate >= bookingInfo.checkOutDate
                    : false
                }
                minDate={moment(new Date())}
                dateValue={moment(bookingInfo.checkOutDate)}
                dateOnChange={(date) => {
                  setBookingInfo({ ...bookingInfo, checkOutDate: date });
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>DEPARTURE TIME</span>
              <TimePicker
                TimeValue={moment(bookingInfo.checkOutDate)}
                setTime={(e) => {
                  setBookingInfo({ ...bookingInfo, checkOutDate: e });
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
        </TravelInputRow>
      </FormContainer>
    </Card>
  );
}

export default BookingInformation;