import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button';
import ActiveMapLegend from 'assets/icons/ActiveMapLegend.svg'
import MapLegend from 'assets/icons/MapLegend.svg';
import FloorPlan from '../FloorPlan';
import Legend from '../Legend'
import StyledBadge from 'components/Badge';
import useWindowDimensions from 'customHook/useWindowDimensions';
import {
  FloorNumbers,
  ViewLegend,
} from './styles';
import colors from 'constants/colors';

const FloorCard = ({eighthFloorRooms, ninthFloorRooms, tenthFloorRooms}) => {
  
  const history = useHistory();
  const [selectedFloor, setSelectedFloor] = useState(8);
  const [isLegendVisible, setIsLegendVisible] = useState(false);
  const { width } = useWindowDimensions()

  return (
    <Card
      additionalstyle={`
        padding: 0 24px;
        height: 100%;

        @media only screen and (max-width: 1200px) {
          background-color: ${colors.black5};
          padding: 0;
        }
      `}
    >
      <CardHeader
        additionalstyle={`
          margin-bottom: 10px;
        `}
      >
        <div className="type">
          FLOOR
          <FloorNumbers>
            <StyledBadge
              className="floor"
              mobile="true"
              color="error"
              badgeContent=" "
              variant="dot"
              invisible={false}
            >
              <div
                className={selectedFloor === 8 ? 'active' : null}
                onClick={() => setSelectedFloor(8)}>
                8
              </div>
            </StyledBadge>
            <StyledBadge
              className="floor"
              mobile="true"
              color="error"
              badgeContent=" "
              variant="dot"
              invisible={false}
            >
              <div
                className={selectedFloor === 9 ? 'active' : null}
                onClick={() => setSelectedFloor(9)}>
                9
              </div>
            </StyledBadge>
            <StyledBadge
              className="floor"
              mobile="true"
              color="error"
              badgeContent=" "
              variant="dot"
              invisible={false}
            > 
              <div
                className={selectedFloor === 10 ? 'active' : null}
                onClick={() => setSelectedFloor(10)}>
                10
              </div>
            </StyledBadge>
          </FloorNumbers>
        </div>
        <ViewLegend
          onClick={() => setIsLegendVisible(!isLegendVisible)}
          active={isLegendVisible}
        >
          {
            isLegendVisible?
              <img src={ActiveMapLegend} alt={'activeMapLegend'}/>:
              <img src={MapLegend} alt={'mapLegend'}/>
          }
          <div>{isLegendVisible? 'HIDE LEGEND': 'VIEW LEGEND'}</div>
        </ViewLegend>
        <Button
          desktop
          onClick={() => history.push('/rooms')}
          additionalstyle={`
            color: ${colors.white1};
            background: ${colors.blue4};
            width: 142px;
            font-size: 14px;
            font-weight: 600;
          `}
        >
          Manage Rooms
        </Button>
      </CardHeader>
      {isLegendVisible? <Legend desktop/> : null}
      <FloorPlan selectedFloor={selectedFloor} desktop backgroundColor={width>1200? colors.grey2:colors.black5} eighthFloorRooms={eighthFloorRooms} ninthFloorRooms={ninthFloorRooms} tenthFloorRooms={tenthFloorRooms}/>
      {isLegendVisible? <Legend mobile/> : null}
    </Card>
  )
}

export default FloorCard;
