import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalAddChargeFailed = ({
  isOpen,
  setIsOpen,
  amount,
  cardNumber,
  retryFunction
}) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>{`Unable to apply a credit of $${Number(amount).toFixed(2)} to the card ending in ${cardNumber?.slice(-4)}.`}</div>
        <Button
          danger
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 79px;
            margin-top: 24px;
            margin-right: 16px;
          `} 
        >
          Cancel
        </Button>
        <Button
          success
          onClick={retryFunction}
          additionalstyle={`
            width: 72px;
            margin-top: 24px;
          `}
        >
          Retry
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalAddChargeFailed;
