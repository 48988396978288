import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from 'redux/groupCheckInModalStatusSlice';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { GET_ALL_AVAILABLE_KIOSKS } from 'graphql/service';
import { useLazyQuery } from '@apollo/client';
import useSocket from 'customHook/useSocket';
import colors from 'constants/colors';
import { Container, Footer, Head } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalKeycardPrinting  = ({
  hidden,
  disabled,
  disableBack,
  buttonLabel="",
  buttonStyle,
}) => {

  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.groupCheckInModalStatusReducer.activeModal);
  const data = useSelector((state) => state.groupCheckInModalStatusReducer.data);
  const groupId = data.groupId
  
  const [options, setOptions] = useState([
    {label: "Mobile Terminal", value: "Mobile Terminal"},
    {label: "Manual Entry", value: "Manual Entry"},
  ])

  const [method, setMethod] = useState("")
  const { connected } = useSocket();

  const [getAllAvailableKiosks] = useLazyQuery(GET_ALL_AVAILABLE_KIOSKS,
    {
      onCompleted: (availableKiosksData) => {
        if(availableKiosksData.availableKiosks.length > 0) {
          const newOptions = availableKiosksData.availableKiosks?.map((kiosk) => ({label: kiosk.username, value: kiosk.kioskId}))
          newOptions.push({label: "Manual Entry", value: "Manual Entry"})
          setOptions(newOptions)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  useEffect(() => {
    if(activeModal === "paymentDevice") {
      getAllAvailableKiosks()
    }
  }, [activeModal])

  const handleBack = () => {
    dispatch(openModal({modal: "customerIdentification"}))
  }
    
  const handleSend = () => {
    if(method === "Mobile Terminal") {
      dispatch(openModal({modal: "kioskPaymentTerminal", data: {groupId, method}}))
    } else if(method === "Manual Entry") {
      dispatch(openModal({modal: "creditCard"}))
    } else {
      if(connected) {
        dispatch(openModal({modal: "kioskPaymentTerminal", data: {groupId, method}}))
      } else {
        message.error({
          content: "Socket is not connected",
          duration: getMessageTimeOut("Socket is not connected"),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  }

  return (
    <MainModal
      isOpen={activeModal === "paymentDevice"}
      setIsOpen={() => dispatch(openModal({modal: "paymentDevice"}))}
      setClose={() => dispatch(closeModal())}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      disabled={disabled}
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          min-height: 248px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <Head>Confirm Identity</Head>
        <div className='text'>
          <div>Which identity scanner device would you like to use?</div>
        </div>
        <Dropdown
          mainPage
          options={options}
          value={options.find(option => option.value === method)?.label || "- Select -"}
          title="PREFERRED METHOD"
          onChange={(e) => {
            setMethod(e)
          }}
          additionalstyle={`
            flex-basis: 10%;
            margin-right: 10px;

            @media only screen and (max-width: 767px) {
              flex-basis: 40%;
            }
          `}
        />
      </Container>
      <Footer>
        <div className='buttonContainer'>
          {!disableBack? null: (
            <div 
              className="back"
              onClick={handleBack}
            >
              Back
            </div>
          )}
          <Button
            success
            additionalstyle={`
              height: 43px;
              width: 72px;
            `}
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </Footer>
    </MainModal>
  );
}

export default ModalKeycardPrinting;
