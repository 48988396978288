import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Note from 'components/Note';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import moment from 'moment';
import { NotesContainer, NavigatorContainer, Navigator } from './styles';

const BookingList = ({ bookings, type, buttonTitle="Manage booking", padButtonTitle="Manage", mobileButtonTitle="Manage" }) => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 5
  const pageCount = Math.ceil(bookings?.length/pageSize)

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    if(pageNumber > 1) {
      setPageNumber((pageNumber) => pageNumber-1)
    }
  }

  const nextPage = () => {
    if(pageNumber < pageCount) {
      setPageNumber((pageNumber) => pageNumber+1)
    }
  }

  return (
    <Card
      additionalstyle={`
        min-height: 388px;

        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          min-height: 396px;
        }
      `}
    >
      <CardHeader>
        <div className='type'>{type}</div>
      </CardHeader>
      <NotesContainer>
        {paginate(bookings, pageSize, pageNumber)?.map((booking, index) => {
          const {
            bookingId,
            checkInDate,
            checkOutDate,
            fullName
          } = booking;
          return (
            <Note
              key={index}
              description={ fullName + ' ' +
                moment(checkInDate).format('M/D/YYYY') +
                ' - ' +
                moment(checkOutDate).format('M/D/YYYY')
              }
              disabled
              buttonTitle={buttonTitle}
              padButtonTitle={padButtonTitle}
              mobileButtonTitle={mobileButtonTitle}
              onClickButton={() =>
                history.push(
                  `/booking-details/${bookingId}`,
                )
              }
            />
          );
        })}
      </NotesContainer>
      {
        bookings?.length>pageSize ? 
          (
            <NavigatorContainer>
              <Navigator
                disabled={pageNumber<=1} 
                onClick={previousPage}
              >
                {'<'} Back
              </Navigator>
              <Navigator
                disabled={pageNumber>= bookings.length/pageSize}
                onClick={nextPage}
              >
                Next {'>'}
              </Navigator>
            </NavigatorContainer>
          ): null
      }
    </Card>
  );
};

export default BookingList;
