import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};

    select, input {
      height: 54px;
      background-position: 98% 50%;
      background-size: 15px 15px;
    }

    span {
      font-weight: 300;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const AutocompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.white1};
  width: 100%;

  .label {
    font-size: 11px;
    font-weight: 300;
    color: ${(props) => (props.mainPage ? colors.grey5 : colors.white1)};
    letter-spacing: 2.5px;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  input {
    color: ${colors.white1};
    background-color: ${colors.grey17};
    font-size: 14px;
    font-weight: 600;
    padding: 9px;
    border: 1px solid ${(props) => props.error? colors.red1: colors.grey1};
    width: 100%;
    height: 40px;

    :focus-visible {
      outline: none;
      background-color: ${colors.black5};
    }
  }

  .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: ${colors.red1};
    font-size: 14px;
    font-weight: 600;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-all;
    overflow: visible;
    text-align: left;
    margin-top: 5px;
  }
`;