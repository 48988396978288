import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalAddChargeSuccessfully = ({
  isOpen,
  setIsOpen,
  amount,
  cardNumber
}) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>{`A credit of $${Number(amount).toFixed(2)} has been successfully applied to the card ending in ${cardNumber?.slice(-4)}.`}</div>
        <Button
          success
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 70px;
            margin-top: 24px;
          `} 
        >
          Close
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalAddChargeSuccessfully;
