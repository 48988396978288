import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { RoomDetailsContainer } from './styles';
import GeneralRoomNotes from './components/GeneralRoomNotes';
import StatusHistory from './components/StatusHistory';
import RoomDetailsHeader from './components/RoomDetailsHeader';
import { GET_ROOM_BY_ROOM_NUMBER } from 'graphql/service';
import { useQuery } from '@apollo/client';
import BookingList from './components/BookingList';
import moment from 'moment';

const RoomDetails = () => {

  const roomNumber = useParams().roomNumber

  const { data: roomData, loading, error, refetch } = useQuery(GET_ROOM_BY_ROOM_NUMBER, {
    variables: { roomNumber },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const {
    roomByNumber: {
      roomId,
      roomType,
      roomNotes,
      roomStatusHistories,
      roomBookings,
    },
  } = roomData;
  const currentBookings = []
  const previousBookings = []
  roomBookings.forEach((roomBooking) => {
    const newBooking = {...roomBooking.booking}
    if (moment(newBooking.checkOutDate) < moment().subtract(1, 'days')) {
      previousBookings.push(newBooking)
    } else {
      currentBookings.push(newBooking)
    }
  })   

  return (
    <>
      <Helmet>
        <title>Room Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalstyle={`
          border: none;
        `}
      />
      <RoomDetailsContainer>
        <RoomDetailsHeader
          roomNumber={roomNumber}
          roomId={roomId}
          roomType={roomType}
          status={roomStatusHistories?.[0]?.status}
          refetch={refetch}
        />
        <Content>
          <ContentColumn>
            <Container>
              <GeneralRoomNotes
                roomId={roomId}
                notes={roomNotes}
                refetch={refetch}
              />
            </Container>
            <Container>
              <BookingList type="CURRENT BOOKINGS" bookings={currentBookings} />
            </Container>
          </ContentColumn>
          <ContentColumn>
            <Container>
              <StatusHistory roomStatusHistories={roomStatusHistories} />
            </Container>
            <Container>
              <BookingList type="PREVIOUS BOOKINGS" bookings={previousBookings} />
            </Container>
          </ContentColumn>
        </Content>
      </RoomDetailsContainer>
      <Footer />
    </>
  );
};

export default RoomDetails;