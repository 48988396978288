import React, { useState } from 'react';
import Card from 'components/Card';
import AlertItem from 'screens/Home/components/AlertItem';
import { useQuery } from '@apollo/client';
import { GET_ALL_ROOM_NOTES } from 'graphql/service'
import { Container, AlertContainer } from './styles';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const Alerts = ({mobile}) => {

  const [alertList, setAlertList] = useState()

  const { loading, error, refetch } = useQuery(
    GET_ALL_ROOM_NOTES,
    {
      pollInterval: 1200000,
      onCompleted: (alertsData)=> {
        if(alertsData) {
          const unreadAlertList = []
          const readAlertList = []
          alertsData.roomNotes.forEach((alert) => {
            const {
              noteId,
              notes,
              createdAt,
              isRead,
              createdBy: {
                createdById,
                fullName
              },
              room: {
                roomId,
                roomNumber
              }
            } = alert
            if(isRead) {
              readAlertList.push(
                <AlertItem
                  key={noteId}
                  mobile={mobile}
                  roomNoteId={noteId}
                  isRead={isRead}
                  roomNumber={roomNumber}
                  roomId={roomId}
                  alertType={'ROOM NOTE'}
                  notes={notes}
                  createdById={createdById}
                  createdByName={fullName}
                  checkInDate={moment(createdAt).format('DD/MM/YYYY h:mmA')}
                />
              )
            } else {
              unreadAlertList.push(
                <AlertItem
                  key={noteId}
                  mobile={mobile}
                  roomNoteId={noteId}
                  isRead={isRead}
                  roomNumber={roomNumber}
                  roomId={roomId}
                  alertType={'ROOM NOTE'}
                  notes={notes}
                  createdById={createdById}
                  createdByName={fullName}
                  checkInDate={moment(createdAt).format('DD/MM/YYYY h:mmA')}
                />
              )
            }
          })
          readAlertList.length = 5
          const alertList = unreadAlertList.concat(readAlertList)
          setAlertList(alertList) 
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  if (error) {
    return <div>Error: {error.message}</div>
  }
  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Container mobile={mobile}>
      <Card
        additionalstyle={`
          padding: 18px 15px;

          @media only screen and (min-width: 767px) {
            font-size: 17px;
          }
        
          @media only screen and (max-width: 767px) {
            font-size: 14px;
          }
        `}
      >
        ALERTS
      </Card>
      <AlertContainer mobile={mobile}>      
        <AlertItem
          //key={noteId}
          mobile={mobile}
          //roomNoteId={noteId}
          //isRead={isRead}
          roomNumber={'1033'}
          //roomId={roomId}
          refetch={refetch}
          type={'note'}
          alertType={'STAYOVER'}
          //createdById={createdById}
          createdByName={'Kenji Lopez-Alt'}
          checkInDate={moment().format('DD/MM/YYYY h:mmA')}
          checkOutDate={moment().add(1, 'days').format('DD/MM/YYYY h:mmA')}
        />
        <AlertItem
          //key={noteId}
          mobile={mobile}
          //roomNoteId={noteId}
          //isRead={isRead}
          roomNumber={'1033'}
          //roomId={roomId}
          refetch={refetch}
          type={'note'}
          alertType={'CONFLICT'}
          //createdById={createdById}
          createdByName={'Kenji Lopez-Alt'}
          checkInDate={moment().format('DD/MM/YYYY h:mmA')}
          checkOutDate={moment().add(1, 'days').format('DD/MM/YYYY h:mmA')}
        />
        {alertList}
        <div>There is nothing else here.</div>
      </AlertContainer>
    </Container>
  )
}

export default Alerts;
