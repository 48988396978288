import React, { useState } from 'react';
import { Container, StyledItem } from './styles';

const Dropdown = ({
  title,
  onChange,
  value,
  error,
  additionalstyle,
  options,
  selectorHeight,
  mainPage,
  disabled,
  readOnly
}) => {

  const [isActive, setIsActive] = useState(false)

  const onSelectValue = (e) => {
    setIsActive(false)
    onChange(e.nativeEvent.target.dataset.value)
  }

  const onSelectInnerText = (e) => {
    setIsActive(false)
    onChange(e.target.innerText)
  }

  return (
    <Container
      additionalstyle={additionalstyle}
      height={selectorHeight}
      mainPage={mainPage}
      disabled={disabled}
      title={title}
      error={error}
    >
      {title && <span>{title}{error? ' (REQUIRED)': null}</span>}
      <div
        className='select'
        onClick={readOnly? null: ()=>{
          if(!disabled) {
            setIsActive(true)
          }
        }}
        onBlur={()=>setIsActive(false)}
        disabled={disabled}
      >
        {value}
      </div>
      {
        isActive ?
          <>
            <div className='options'> 
              {
                options.map((option, index) => {
                  if(typeof option==='object') {
                    return (
                      <StyledItem
                        className="option"
                        key={option.value}
                        onClick={onSelectValue}
                        data-value={option.value}
                      >
                        {option.label}
                      </StyledItem>
                    )
                  } else {
                    return (
                      <StyledItem
                        className="option"
                        key={index}
                        onClick={onSelectInnerText}
                      >
                        {option}
                      </StyledItem>
                    )
                  }
                })
              }
            </div>
            <div
              className="outerContainer"
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 2000,
                display: isActive? 'block': 'none'
              }}
              onClick={() => {
                setIsActive(false)
              }}
            >
            </div>
          </>: null
      }
    </Container>
  )
}

export default Dropdown;
