import styled from 'styled-components';
import colors from 'constants/colors';

export const BillItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.grey3};
  row-gap: 8px;
  padding: 16px;
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  div {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

export const BillOfSaleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${colors.black5};
  margin-top: 5px;
    
  .value {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    letter-spacing: 0.5px;
    float: right;
  }

  .total {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const CouponContainer = styled.div`
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  background-color: ${colors.grey3}; 
  margin-top: 16px;
`;