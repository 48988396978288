import React from 'react';
import MainModal from 'components/ModalMain';
import { Container } from './styles';

const ModalBookingNotComplete = ({ hidden, buttonStyle, success, buttonLabel, isOpen, setIsOpen }) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      buttonLabel={buttonLabel}
      hidden={hidden}
      success={success}
      buttonStyle={buttonStyle}
      additionalstyle={`
        max-width: 679px;
        .modal-content{
          height: 122px;
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: -5px;
            .modal-title {
              font-size: 22px;
              font-weight: 600;
            }
          }          
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 231px;
      `}
    >
      <Container>
        <div>All required fields must be complete before a booking can be created.</div>
      </Container>
    </MainModal>
  );
};

export default ModalBookingNotComplete;
