import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 10px;
    width: 370px;
    min-height: 120px;
    padding: 16px;
    border: 1px solid ${colors.grey9};
    background-color: ${colors.grey2};

    .cancelledAt {
      color: ${colors.white1};
      font-size: 12px;
      font-weight: 600;
    }

    .cancelledBy {
      color: ${colors.green1};
      font-size: 12px;
      font-weight: 600;
    }

    .tooltip-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
      color: ${colors.white1};
      font-size: 12px;
      font-weight: 600;
    }

    .tooltip-text {
      color: ${colors.white1};
      font-size: 10px;
      font-weight: 300;
    }

    .tooltip-highLight {
      color: ${colors.red2};
      font-size: 14px;
      font-weight: 600;
    }
`;
