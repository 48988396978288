import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentInfo, cleanPaymentInfo } from 'redux/actions'
import { usePaymentForm } from 'customHook/usePaymentForm';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import Inputmask from 'react-input-mask';
import {
  creditCardMask,
  expiryDateMask,
  cvvMask,
} from './inputMasks'
import Autocomplete from "react-google-autocomplete";
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT_CARD } from 'graphql/service';
import { detectCardType } from 'utils/detectCardType';
import checkMark from 'assets/svg/checkMark.svg'
import { Head, Container, AutocompleteContainer, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalAddCreditCard = ({hidden, buttonStyle, buttonLabel='Change Card', groupId}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [creditCardType, setCreditCardType] = useState('')
  const [coverInput, setCoverInput] = useState('')
  const dispatch = useDispatch()
  const paymentInfo = useSelector(state => state.paymentInfoReducer.paymentInfo)
  const { paymentError, setPaymentError, isValidatePaymentInfo } = usePaymentForm(creditCardType)

  const [createPaymentCard] = useMutation(CREATE_PAYMENT_CARD, 
    {
      onCompleted: (data) => {
        if(data) {
          message.success('Add payment card successfully!')
          setIsOpen(false)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  useEffect(()=> {
    return () => {
      dispatch(cleanPaymentInfo())
      setCoverInput('')
      creditCardMask.mask = '9999 9999 9999 9999'
    }    
  },[dispatch])

  const cleanFunction = () => {
    const googleElements = document.getElementsByClassName("pac-container")
    googleElements[googleElements.length-1].remove()
  }

  const onChangeCreditCard = (e) => {

    setCoverInput(e.target.value)
    dispatch(setPaymentInfo({...paymentInfo, creditCardNumber: e.target.value}))
    if(e.target.value.length < 19) {
      setCreditCardType('')
      setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
    } else {
      const type = detectCardType(e.target.value.replace(/\s/g, ''))
      if(type.length > 0) {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
      } else {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
      }
      setCreditCardType(type)
    }
  }

  const handleSubmit = () => {
    if (isValidatePaymentInfo()) {
      createPaymentCard({
        variables: {
          input: {
            cardHolderName: paymentInfo.cardHolderName,
            creditCardNumber: paymentInfo.creditCardNumber.replace(/\s/g, ''),
            cardExpirationDate: paymentInfo.cardExpirationDate.replace(/[\s/]/g, ''),
            cardCVV: paymentInfo.cardCVV,
            billingAddress: paymentInfo.billingAddress,
            groupId: groupId,
            isPrimary: false
          }
        }
      })
    }
  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      cleanFunction={cleanFunction}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 646px;
        .modal-content{
          min-height: 464px;
          .close {
            z-index: 200;
          }
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 65px;
      `}
    >
      <Head>Add Credit Card</Head>
      <Container>
        <Input
          title="CARDHOLDER NAME"
          value={paymentInfo.cardHolderName}
          error={paymentError.cardHolderNameError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardHolderName: e.target.value}))
            if(paymentError.cardHolderNameError) {
              setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: false}))
            }
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Inputmask
          mask={creditCardMask.mask}
          maskPlaceholder=''
          value={coverInput}
          error={paymentError.creditCardNumberError}
          errorMessage={"The card number entered is not valid."}
          onChange={onChangeCreditCard}
        >
          <Input
            title="CARD NUMBER"
            maxLength={19}
            additionalstyle={`
              width:60%;
              input{
                ${
                  creditCardType? `
                    background-image: url(${checkMark});
                    background-repeat: no-repeat;
                    background-position: 80% 50%;
                  `: null
                }
              }
            `}
          />
        </Inputmask>
        <Inputmask
          mask={expiryDateMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardExpirationDate}
          error={paymentError.cardExpirationDateError}
          onChange={(e)=> {
            dispatch(setPaymentInfo({...paymentInfo, cardExpirationDate: e.target.value}))
            if(paymentError.cardExpirationDateError) {
              setPaymentError((paymentError)=>({...paymentError, cardExpirationDateError: false}))
            }
          }}
        >
          <Input
            title="EXPIRY"
            additionalstyle={`
              width: 18%;
              margin-left:2%;
            `}
          />
        </Inputmask>
        <Inputmask
          mask={cvvMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardCVV}
          error={paymentError.cardCVVError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardCVV: e.target.value}))
            if(paymentError.cardCVVError) {
              setPaymentError((paymentError)=>({...paymentError, cardCVVError: false}))
            }
          }}
        >
          <Input
            title="CCV"
            additionalstyle={`
              width: 18%;
              margin-left:2%;
            `}
          />
        </Inputmask>
        <AutocompleteContainer
          error={paymentError.billingAddressError}
        >
          <div className='label'>{'ADDRESS'}</div>
          <Autocomplete
            autoComplete="new-password"
            apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
            value={paymentInfo.billingAddress}
            error={paymentError.billingAddressError}
            onChange={(e) => {
              dispatch(setPaymentInfo({...paymentInfo, billingAddress: e.target.value}))
            }}
            onPlaceSelected={(place) => {
              dispatch(setPaymentInfo({...paymentInfo, billingAddress: place.formatted_address}))
            }}
          />
          {paymentError.billingAddressError? <div className="warning">{'This is a required field.'}</div>: null}
        </AutocompleteContainer>
      </Container>
      <Footer>
        <Button
          success
          additionalstyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalAddCreditCard;
