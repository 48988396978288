import React, { useState } from 'react';
import Notes from 'components/Notes';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { CREATE_ROOM_NOTE, DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID } from 'graphql/service';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const GeneralRoomNotes = ({ notes, refetch, roomId }) => {

  const userId = localStorage.getItem('userId')
  const [note, setNote] = useState('');
  const pageSize = 3

  const [addNote] = useMutation(CREATE_ROOM_NOTE, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  });
  const [removeNote] = useMutation(DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  });
  const onAddNote = async () => {
    if (note) {
      addNote({
        variables: {
          input: {
            roomId,
            createdBy: userId,
            notes: note,
            isRead: false
          },
        },
      });
      setNote('')
    } else {
      const content = "Note can't be empty"
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }

  const onRemoveNote = async (noteId) => {
    removeNote({ variables: { noteId: noteId } })
  }

  return (
    <Card
      additionalstyle={`
        min-height: 396px;

        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          min-height: 438px;
        }

        @media only screen and (max-width: 767px) {
          min-height: 406px;
        }
      `}
    >
      <CardHeader
        additionalstyle={`
          margin-bottom: 10px;
        `}
      >
        <div className='type'>GENERAL ROOM NOTES</div>
      </CardHeader>
      <Notes
        notes={notes}
        note={note}
        setNote={setNote}
        onAddNote={onAddNote}
        onRemoveNote={onRemoveNote}
        pageSize={pageSize}
      />
    </Card>
  );
};

export default GeneralRoomNotes;
