import styled from 'styled-components';
import colors from 'constants/colors';

export const Label = styled.label`
  font-size: 16px;
  color: ${colors.white1};
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  /* When the checkbox is checked, add a grey background */
  input:checked ~ span {
    background-color: ${(props) =>(props.backgroundColor ? props.backgroundColor:colors.grey3)};
  }

  /* Show the checkmark when checked */
  input:checked ~ span:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  span::after {
    left: 4px;
    top: -1px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-color: ${(props) =>(props.checkColor ? props.checkColor:colors.blue4)};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

/* Hide the browser's default checkbox */
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0px;
  height: 0px;
`;

/* Create a custom checkbox */
export const CheckBox = styled.span`
  position: relative;
  top: 4px;
  left: 0;
  margin-right: 10px;
  height: 15px;
  width: 15px;
  background-color: ${(props) =>(props.backgroundColor ? props.backgroundColor:colors.grey3)};
  border: 1px solid ${colors.grey18};
  transform: scale(1.27);
  
  ${({ additionalstyle }) => additionalstyle};
  /* Create the checkmark/indicator (hidden when not checked) */
  &::after {
    content: '';
    position: absolute;
    display: none;
  }
`;
