import React, { useState } from 'react';
import { RevenueReportSubHeader } from '../styles';
import Dropdown from 'components/Dropdown';

const SubHeader = () => {

  const [choice, setChoice] = useState('This month to last month')

  return (
    <RevenueReportSubHeader>
      <div className="dropDownContainer">
        <span className="title">COMPARE: </span>
        <Dropdown
          mainPage
          options={['This month to last month', 'Last month to this month']}
          additionalstyle={`
            flex-basis: 100%;
          `}
          value={choice}
          onChange={(e)=>setChoice(e)}
        />
      </div>
    </RevenueReportSubHeader>
  );
};

export default SubHeader;
