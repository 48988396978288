import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import ModalMakeCardPrimarySuccessfully from 'screens/ModalMakeCardPrimarySuccessfully';
import ModalMakeCardPrimaryFailed from 'screens/ModalMakeCardPrimaryFailed';
import { SET_CREDIT_CARD_PRIMARY } from 'graphql/service';
import { useMutation } from '@apollo/client';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalMakeCardPrimary = ({
  isOpen,
  setIsOpen,
  cardId,
  amount,
  originCardNumber,
  newCardNumber,
  refetch
}) => {

  
  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
  const [failedIsOpen, setFailedIsOpen] = useState(false)

  const [setCreditCardPrimary] = useMutation(SET_CREDIT_CARD_PRIMARY, {
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
      setSuccessfullyIsOpen(true)
    },
    onError: (error) => {
      setIsOpen(false)
      setFailedIsOpen(true)
    }
  })

  const handleSubmit = () => {
    setCreditCardPrimary({
      variables: {
        input: {
          paymentCardId: cardId
        }
      }
    })
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            height: 100%;
            .close {
              z-index: 200;
            }   
            .modal-body{
              position: relative;
              height: 100%;
            }
          }
          background: ${colors.grey3};
          top: 179px;
        `}
      >
        <Container>
          <div className='text'>Are you sure you want to make this the primary card?</div>
          <Button
            success
            onClick={handleSubmit}
            additionalstyle={`
              width: 166px;
              margin-top: 24px;
            `} 
          >
            Yes, make primary
          </Button>
        </Container>
      </MainModal>
      <ModalMakeCardPrimarySuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
      />
      <ModalMakeCardPrimaryFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={amount}
        originCardNumber={originCardNumber}
        newCardNumber={newCardNumber}
      />
    </>
  );
}

export default ModalMakeCardPrimary;
