import { useDispatch, useSelector } from 'react-redux';

const useSocket = () => {

  const dispatch = useDispatch();
  const connected = useSelector((state) => state.socketReducer.connected);
  const error = useSelector((state) => state.socketReducer.error);

  const emit = (event, data) => {
    dispatch({
      type: 'socket/emit',
      payload: { event, data }
    });
  };

  const on = (event, callback) => {
    dispatch({
      type: 'socket/on',
      payload: { event, callback }
    });
  }

  const off = (event) => {
    dispatch({
      type: 'socket/off',
      payload: { event }
    });
  }

  return {
    connected,
    error,
    emit,
    on,
    off
  };
};

export default useSocket;
