import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import { Container, Head, Footer } from './styles';
import { message } from 'antd';

const ModalGroupCancel = ({
  hidden,
  danger,
  buttonStyle,
  buttonLabel="Cancel Entire Group",
  setCancelRoomParams,
  setConfirmIsOpen 
}) => {
  
  const [isOpen, setIsOpen] = useState(false)
  const [cancellationOption, setCancellationOption] = useState('-Select-') 
  const [cancellationReason, setCancellationReason] = useState('')  
  const [cancellationFee, setCancellationFee] = useState(true)

  const handleSubmit = () => {
    if(cancellationOption==='-Select-') {
      message.error('Please select a reason for cancellation')
      return
    } else{
      setCancelRoomParams({
        cancellationReason: cancellationOption==='others'? cancellationReason.length===0? 'others': cancellationReason: cancellationOption,
        cancellationFee: cancellationFee? null: 0
      })
      setConfirmIsOpen(true)
      setIsOpen(false)
    }
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      buttonLabel={buttonLabel}
      danger={danger}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 679px;
        .modal-content{
          height: 381px;
          .close {
            z-index: 200;
          }
          .modal-header {
            .modal-title {
              font-size: 22px;
              font-weight: 600;
            }
          }          
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 136px;
      `}
    >
      <Head>
        Cancel entire group?
      </Head>
      <Container>
        <div className="groupTitle">What is the reason for the cancellation?</div>
        <Dropdown
          name="reason"
                    options={[
            'Change of Plans',
            'Personal Reasons',
            'Found a Better Option',
            'Scheduling Conflict',
            'Health Issues',
            'others'
          ]}
          value={cancellationOption}
          title="REASON FOR CANCELLATION"
          onChange={(e) => {
            setCancellationReason('')
            setCancellationOption(e)
          }}
          additionalstyle={`
            width: 100%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
        />
        <TextArea
          title="TYPE A REASON HERE:"
          value={cancellationReason}
          onChange={(e) => {
            setCancellationOption('others')
            setCancellationReason(e.target.value)
          }}
          additionalstyle={`
            flex-basis: 100%;
            
            textarea {
              min-height: 54px;
              height: 54px;
            }
          `}
        />
      </Container>
      <Footer>
        <Checkbox
          checked={cancellationFee}
          onChange={(e) => setCancellationFee(e)}
          title="Apply cancellation fee"
        />
        <Button
          danger
          additionalstyle={`
            height: 50px;
            width: 84px;
          `}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
};

export default ModalGroupCancel;
