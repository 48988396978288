import React from 'react';
import { Label, Input, CheckBox } from './styles';

const Checkbox = ({
  disabled,
  onChange,
  checked,
  title,
  name,
  additionalstyle,
  backgroundColor,
  checkColor
}) => (
  <Label
    backgroundColor={backgroundColor}
    checkColor={checkColor}
  >
    <Input
      name={name}
      disabled={disabled}
      type="checkbox"
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      checked={checked}
    />
    <CheckBox
      additionalstyle={additionalstyle}
      backgroundColor={backgroundColor}
    />
    {title}
  </Label>
);

export default Checkbox;
