import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ModalAddChargeSuccessfully from 'screens/ModalAddChargeSuccessfully';
import ModalAddChargeFailed from 'screens/ModalAddChargeFailed';
import { useMutation } from '@apollo/client';
import { CREATE_CHARGE } from 'graphql/service';
import { Head, Container, Footer } from './styles';

const ModalAddCredit = ({
  hidden,
  buttonStyle,
  billId,
  cardNumber,
  max,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
  const [failedIsOpen, setFailedIsOpen] = useState(false)
  const [creditDetails, setCreditDetails] = useState('')
  const [creditAmount, setCreditAmount] = useState('')
  const [amountError, setAmountError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Credit cannot be greater than the total booking price.')
  const [createCredit] = useMutation(CREATE_CHARGE, {
    onCompleted: () => {
      setCreditAmount('')
      setCreditDetails('')
      setSuccessfullyIsOpen(true)
      setIsOpen(false)
      refetch()
    },
    onError: (error) => {
      setFailedIsOpen(true)
      setIsOpen(false)
    }
  })

  const handleAddCredit = () => {
    if(Number(creditAmount) > max) {
      setAmountError(true)
    } else {
      createCredit({
        variables: {
          input: {
            billId: billId,
            type: 'DISCOUNT',
            amount: Number(creditAmount),
            description: creditDetails,
          },
        },
      })
    }
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel="Add Credit"
        buttonStyle={buttonStyle}
        hidden={hidden}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 139px;
        `}
      >
        <Head>Add Credit</Head>
        <Container>
          <TextArea      
            title="CREDIT DETAILS"
            required
            rows={4}
            value={creditDetails}
            onChange={(e) => setCreditDetails(e.target.value)}
            additionalstyle={`
              flex-basis: 100%;
            `}
          />
        </Container>
        <Footer>
          <Input
            title="AMOUNT"
            required
            value={creditAmount}
            error={amountError}
            errorMessage={errorMessage}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
                setCreditAmount(inputValue);
              }
            }}
            additionalstyle={`
              width: 31%;

              input {
                padding-left: 22px;
              }

              ::before {
                content: '$';
                font-size: 16px;
                font-weight: 600;
                position: absolute;
                left: 12px;
                top: 32px;

                @media only screen and (min-width: 767px) and (max-width: 1200px) {
                  top: 36px;
                }

                @media only screen and (max-width: 767px) {
                  top: 34px;
                }
              }
              
              .warning {
                white-space: nowrap;
              }
            `}
          />
          <Button
            success
            disabled={!creditAmount.length || !creditDetails.length}
            additionalstyle={`
              width: 84px;
              margin-top: 25px;
            `}
            onClick={handleAddCredit}
          >
            Submit
          </Button>
        </Footer>
      </MainModal>
      <ModalAddChargeSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={creditAmount}
        cardNumber={cardNumber}
      />
      <ModalAddChargeFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={creditAmount}
        cardNumber={cardNumber}
        retryFunction={handleAddCredit}
      />
    </>
  );
}

export default ModalAddCredit;
