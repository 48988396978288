import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import colors from 'constants/colors';
import { Container, Footer, Head } from './styles';

const ModalCheckOut = ({
  buttonStyle,
  buttonLabel="Group Check Out",
  hidden,
  checkedInBookings,
  checkOutBookings,
  setCheckOutBookings,
  setCheckOutConfirmIsOpen
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const checkBoxArray = checkedInBookings?.map((booking, index) => {
    if(booking.status === 'CHECKED_IN') {
      const checked = checkOutBookings.find(checkOutBooking => checkOutBooking.bookingId === booking.bookingId)
      return (
        <Checkbox
          key={booking.bookingId}
          checked={!!checked}
          onChange={(e) => {
            const newCheckOutBookings = [...checkOutBookings]
            if(checked) {
              newCheckOutBookings.splice(newCheckOutBookings.findIndex(checkOutBooking => checkOutBooking.bookingId === booking.bookingId), 1)
            } else {
              newCheckOutBookings.push(booking)
            }
            setCheckOutBookings(newCheckOutBookings)
          }}
          title={'Room ' + booking.roomBookings?.[0]?.room?.roomNumber}
        />
      )
    } else {
      return null
    }
  })

  const handleContinue = () => {
    setIsOpen(false)
    setCheckOutConfirmIsOpen(true)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          min-height: 205px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <Head>Check Out</Head>
        <div className='text'>
          <div>Which rooms would you like to check out?</div>
        </div>
        <div className='list'>
          {checkBoxArray}
        </div>
      </Container>
      <Footer>
        <Button
          success
          disabled={checkOutBookings?.length === 0}
          additionalstyle={`
            height: 40px;
            width: 104px;
          `}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalCheckOut;
