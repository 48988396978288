import styled from 'styled-components';
import colors from 'constants/colors';

export const StatusBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.grey1};
  border-radius: 50%/150%;
  font-size: ${({fontSize}) => (fontSize ? fontSize : '12px')};
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
  width: ${({width}) => (width ? width : '76px')};
  height: ${({height}) => (height ? height : '23px')};
  background:   ${({status}) => 
    status==='CHECKED_IN'? colors.green9:
    (status==='CANCELLED' || status==='EXPIRED')? colors.red2: 
    colors.orange5};
  color: ${({color}) => (color? color: colors.white1)};
  margin: ${({margin}) => (margin ? margin : '0 15px')};
`;
