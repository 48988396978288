import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalViewImage = ({hidden, buttonStyle, buttonLabel="View Image", fullName, image}) => {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 352px;
        .modal-content{
          height: 471px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            top: 20px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <img src={image} alt={'Staff'} width={256} height={384}/>
        <div>{fullName}</div>
      </Container>
    </MainModal>
  );
}

export default ModalViewImage;
