import React, { useState, useEffect } from 'react';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { usePlacesWidget } from 'react-google-autocomplete'

const GuestInformation = ({
  btnInfo,
  disabled,
  customerInfo,
  setCustomerInfo,
  customerInfoError,
  setCustomerInfoError
}) => {

  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      setCustomerInfo((customerInfo) => ({...customerInfo, billingAddress: place.formatted_address}))
    },
    options: {
      types: ['address'],
    },
  })

  useEffect(() => {
    if(phoneNumberValidity) {
      setCustomerInfoError({...customerInfoError, phoneNumberError: false})
      setCustomerInfo((customerInfo) => ({...customerInfo, phoneNumber: phoneNumber}))
    }
  }, [phoneNumberValidity])


  return (
    <Card
      additionalstyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader
        additionalstyle={disabled? null:`
          @media only screen and (max-width: 767px) {
            
            .type {
              width: 100%;
              margin-bottom: 10px; 
            }

            .buttonContainer {
              width: 100%;
              justify-content: space-between;
            }
            
            flex-direction: column;
            justify-content: center;
          }
        `}
      >
        <div className='type'>GUEST INFORMATION</div>
        <div className='buttonContainer'>{btnInfo}</div>
      </CardHeader>
      <FormContainer>
        <Input
          disabled={disabled}
          mainPage
          title="NAME"
          value={customerInfo.fullName}
          error={customerInfoError.fullNameError}
          onChange={(e) => {
            setCustomerInfo({...customerInfo, fullName: e.target.value})
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          disabled={disabled}
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={customerInfo.billingAddress}
          error={customerInfoError.billingAddressError}
          onChange={(e) => {
            setCustomerInfo({...customerInfo, billingAddress: e.target.value})
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          disabled={disabled}
          mainPage
          title="EMAIL"
          value={customerInfo.email}
          error={customerInfoError.emailError}
          onChange={(e) => {
            setCustomerInfo({...customerInfo, email: e.target.value})
          }}
          additionalstyle={`
            flex-basis: 63%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin: 0;
            }
          `}
        />
        <PhoneNumberInput
          disabled={disabled}
          mainPage
          title="PHONE"
          error={customerInfoError.phoneNumberError}
          onChangeNumber={setPhoneNumber}
          setPhoneNumberValidity={setPhoneNumberValidity}
          onBlur={() => {
            if(!phoneNumberValidity&&phoneNumber.length>0) {
              setCustomerInfoError({...customerInfoError, phoneNumberError: true})
            }
          }}
          additionalstyle={`
            flex-basis: 35%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
            }
          `}
        />
        <Input
          disabled={disabled}
          mainPage
          title="TRAVEL DOCUMENT #"
          value={customerInfo.travelDocumentNumber}
          onChange={(e) => {
            setCustomerInfo({...customerInfo, travelDocumentNumber: e.target.value})
          }}
          additionalstyle={`
            flex-basis: 53%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin: 0;
            }
          `}
        />
        <Dropdown
          disabled={disabled}
          mainPage
          options={[
            "Passport",
            "Driver's License"
          ]}
          title="TRAVEL DOCUMENT TYPE"
          value={customerInfo.travelDocumentType}
          onChange={(e) => {
            setCustomerInfo({...customerInfo, travelDocumentType: e})
          }}

          additionalstyle={`
            flex-basis: 45%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
            }
          `}
        />
      </FormContainer>
    </Card>
  )
}

export default GuestInformation;
